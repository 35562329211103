import "react-calendar/dist/Calendar.css";
import "./custom-calendar.css";

import Calendar from "react-calendar";

import Stack from "@mui/material/Stack";

import MuiButton from "src/components/Button/MuiButton";
import { getTodayDate } from "src/utils";
import { useDispatch, useSelector } from "src/redux/store";
import { setSelectedDate } from "src/redux/slices/mainPage";

export default function ProgramTab() {
  const { selectedDate } = useSelector((state) => state.mainPage);
  const dispatch = useDispatch();

  return (
    <Stack spacing={2} className="programs-component">
      <MuiButton onClick={() => dispatch(setSelectedDate(getTodayDate()))}>
        Today Schedule
      </MuiButton>
      <Calendar
        onChange={(date) => dispatch(setSelectedDate(date.getTime()))}
        value={new Date(selectedDate)}
      />
    </Stack>
  );
}
