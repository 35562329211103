import axios from "axios";

import CONFIG from "src/config";
import { setSession, getStoredToken } from "./jwt";

const _axios = axios.create({ baseURL: CONFIG.apiUrl });
_axios.defaults.timeout = 20000;

const setNotLoggedIn = () => {
  setSession(null, null);
  window.location = "/login";
  // We can also use React navigate from outside a component
  // https://reactnavigation.org/docs/navigating-without-navigation-prop/
};

_axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (error.response) {
      const hasProtectedUrls =
        error.config.url.indexOf("getrefreshtoken") !== -1 ||
        error.config.url.indexOf("login") !== -1;
      if (
        error.response.status === 401 &&
        !originalConfig._retry &&
        !hasProtectedUrls
      ) {
        console.log("Error1");
        originalConfig._retry = true;
        const { refreshToken } = getStoredToken();
        try {
          const _response = await _axios.post("/getrefreshtoken/", {
            refresh: refreshToken,
            clientId: CONFIG.clientId,
            secret: CONFIG.secret,
          });
          setSession(_response.data.access_token, _response.data.refresh_token);
          originalConfig.headers.Authorization = String(
            `Bearer ${_response.data.access_token}`
          );
          return _axios(originalConfig);
        } catch (_error) {
          console.log(_error.response);
          setNotLoggedIn();
          return Promise.reject(_error);
        }
      } else {
        throw error;
      }
    }
  }
);

export default _axios;
