import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

// import monitorReducersEnhancer from "./enhancers/monitorReducers";
// import loggerMiddleware from "./middleware/logger";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => {
  //   return process.env.NODE_ENV !== "production"
  //     ? getDefaultMiddleware().concat([loggerMiddleware, ...getDefaultMiddleware()])
  //     : getDefaultMiddleware();
  // },
  // enhancers: [monitorReducersEnhancer],
  devTools: process.env.NODE_ENV !== "production",
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch();

export { useSelector, useDispatch };
export default store;
