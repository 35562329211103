import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "react-notifications/lib/notifications.css";

import { Fragment } from "react";
import { NotificationContainer } from "react-notifications";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import AppLayout from "src/container/AppLayout";
import MainRoute from "./router";
import MainLoadingScreen from "./components/Loading/MainLoadingScreen";
// import AlertModal from "./components/AlertModal";
import { AlertModalContextProvider } from "./contexts/AlertModalContext";
import useAuth from "./hooks/useAuth";
import { MainModalProvider } from "./contexts/MainModalContext";
import AlertModal from "./components/AlertModal";

function App() {
  const { isInitialized } = useAuth();
  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppLayout>
          {isInitialized ? (
            <AlertModalContextProvider>
              <AlertModal />
              <MainModalProvider>
                <MainRoute />
              </MainModalProvider>
            </AlertModalContextProvider>
          ) : (
            <MainLoadingScreen sx={{ minHeight: "100vh" }} />
          )}
        </AppLayout>
        {/* <AlertModal /> */}
        <NotificationContainer />
      </LocalizationProvider>
    </Fragment>
  );
}

export default App;
