import "./style.scss";

import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { Radio, InputField } from "src/components";
import MuiInput from "src/components/InputText/MuiInput";
import { useSelector, useDispatch } from "src/redux/store";
import { setOverlay } from "src/redux/slices/overlay";

export default function TimingComponent(props) {
  const { overlay } = useSelector((state) => state.overlay);
  const dispatch = useDispatch();
  const {program} = props;
  const [start, setStart] = useState(() => {
    if (overlay.start) {
      if (overlay.start.startsWith("now+")) {
        return Number(overlay.start.replace("now+", ""));
      } else {
        return moment(overlay.start, ["YYYY.MM.DD HH:mm:ss"]).valueOf();
      }
    } else {
      return 10;
    }
  });
  const [end, setEnd] = useState(() => {
    if (overlay.end) {
      if (overlay.end.startsWith("now+")) {
        return Number(overlay.end.replace("now+", ""));
      } else {
        return moment(overlay.end, ["YYYY.MM.DD HH:mm:ss"]).valueOf();
      }
    } else {
      return 10;
    }
  });
  const [startType, setStartType] = useState(() => {
    if (overlay.start) {
      if (overlay.start.startsWith("now+")) {
        return Number(overlay.start.replace("now+", "")) ? "is" : "im";
      } else {
        return "st";
      }
    } else {
      return "is";
    }
  });
  const [endType, setEndType] = useState(() => {
    if (overlay.end) {
      if (overlay.end.startsWith("now+")) {
        return Number(overlay.end.replace("now+", "")) ? "is" : "im";
      } else if (overlay.end.startsWith("2100")) {
        return "im";
      } else {
        return "st";
      }
    } else {
      return "im";
    }
  });

  useEffect(() => {
    // if (start <= end) {
    //   dispatch(setOverlay({ start: `now+${start}`, end: `now+${end}` }));
    // } else {
    //   dispatch(setOverlay({ start: `now+${start}`, end: `now+${start}` }));
    // }
    let newEnd, newStart;
    if (startType === "st") {
      if (endType === "st" && start > end) {
        newEnd = start;
      }
      newStart = moment(start).format("YYYY.MM.DD HH:mm:ss");
    } else {
      if (endType === "is" && start > end) {
        newEnd = start;
      } else if (
        endType === "st" &&
        moment(Number(start) + Number(end)) < moment().valueOf()
      ) {
        newEnd = moment().valueOf();
      }
      newStart = `now+${start}`;
    }
    if (endType === "st") {
      newEnd = moment(end).format("YYYY.MM.DD HH:mm:ss");
    } else if (endType === "im") {
      newEnd = "2100.12.31 00:00:00";
    } else {
      newEnd = `now+${end}`;
    }
    dispatch(setOverlay({ start: newStart, end: newEnd }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  useEffect(() => {
    if(!overlay.id) {
      if(Object.keys(program).length !== 0) {
        setStartType('st');
        setEndType('st');
        setStart(program.startTime);
        setEnd(program.stopTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeType = (type, value) => {
    if (type === "startType") {
      if((Object.keys(program).length > 0 && !overlay.id) || overlay.programId > 0) {
        return false;
      }
      if (value === "is") {
        setStart(10);
      } else if (value === "st") {
        setStart(new Date().valueOf());
      } else {
        setStart(0);
      }
      setStartType(value);
    } else if (type === "endType") {
      if (value === "is") {
        setEnd(150);
      } else if (value === "st") {
        if(Object.keys(program).length !== 0 || overlay.programId > 0)
          setEnd(program.stopTime);
        else
          setEnd(new Date().valueOf());
      } else {
        if(Object.keys(program).length !== 0 || overlay.programId) {
          return false;
        }
        setEnd(0);
      }
      setEndType(value);
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ flex: "1 1 0" }} p={2}>
        <RadioGroup
          name="startType"
          value={startType}
          onChange={(e) => changeType("startType", e.target.value)}
        >
          <Radio value="is" label="In Seconds" />
          <Radio value="st" label="Specific Time" />
          <Radio value="im" label="Immediately" />
        </RadioGroup>
        {startType === "is" && (
          <InputField
            label="Seconds"
            type="number"
            width="150px"
            value={start.toString()}
            onChange={(e) => {
              setStart(parseInt(e.target.value));
            }}
          />
        )}
        {startType === "st" && (
          <DateTimePicker
            ampm={false}
            disabled={(Object.keys(program).length > 0 && !overlay.id) || overlay.programId > 0}
            value={new Date(start)}
            onChange={(newValue) => setStart(newValue.valueOf())}
            minDate={new Date()}
            inputFormat="MM/dd/yyyy HH:mm:ss"
            mask="__/__/____ __:__:__"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            renderInput={(params) => (
              <MuiInput
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root.MuiInputBase-root": {
                    borderRadius: "4px !important",
                    border: "1px solid #8EC044 !important",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                    {
                      color: "black !important",
                      width: "100%",
                      backgroundColor: "white !important",
                    },
                  width: "100%",
                }}
              />
            )}
          />
        )}
      </Box>
      <Box sx={{ flex: "1 1 0" }} p={2}>
        <RadioGroup
          name="endType"
          value={endType}
          onChange={(e) => changeType("endType", e.target.value)}
        >
          <Radio value="is" label="In Seconds" />
          <Radio value="st" label="Specific Time" />
          <Radio value="im" label="Never" />
        </RadioGroup>
        {endType === "is" && (
          <InputField
            label="Seconds"
            type="number"
            width="150px"
            value={end.toString()}
            onChange={(e) => {
              setEnd(parseInt(e.target.value));
            }}
          />
        )}
        {endType === "st" && (
          <DateTimePicker
            ampm={false}
            disabled={(Object.keys(program).length > 0 && !overlay.id) || overlay.programId > 0}
            value={end}
            onChange={(newValue) => setEnd(newValue.valueOf())}
            minDate={startType === "st" ? new Date(start) : new Date()}
            inputFormat="MM/dd/yyyy HH:mm:ss"
            mask="__/__/____ __:__:__"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            renderInput={(params) => (
              <MuiInput
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root.MuiInputBase-root": {
                    borderRadius: "4px !important",
                    border: "1px solid #8EC044 !important",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                    {
                      color: "black !important",
                      width: "100%",
                      backgroundColor: "white !important",
                    },
                  width: "100%",
                }}
              />
            )}
          />
        )}
      </Box>
    </Stack>
  );
}
