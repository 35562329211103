import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import CONFIG from "src/config";
import { initState } from "src/redux/slices/mainPage";
import { useDispatch } from "src/redux/store";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const appDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      const email = searchParams.get("email");
      const password = searchParams.get("password");
      if (email && password) {
        try {
          const accessToken = window.localStorage.getItem("accessToken");
          if (accessToken) {
            await logout();
          }
          await login(email, password);
        } catch (error) {
          NotificationManager.error(error.response.data.msg);
          setSearchParams({});
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } else {
        try {
          const accessToken = window.localStorage.getItem("accessToken");
          if (accessToken) {
            setSession(accessToken);
            const response = await axiosInstance.get("/me");
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                user: response.data,
              },
            });
          } else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (email, password) => {
    const response = await axiosInstance.post("/login", {
      email,
      password,
      clientId: CONFIG.clientId,
      secret: CONFIG.secret,
    });
    if (response.data && response.data.status === "success") {
      // eslint-disable-next-line camelcase
      setSearchParams({});
      const { access_token, refresh_token, ...user } = response.data;
      setSession(access_token, refresh_token);
      await dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    }
  };

  const logout = async () => {
    const response = await axiosInstance.post("/logout");
    if (response.data) {
      // console.log(configureAppStore().getState(), configureAppStore());
      await appDispatch(initState());
      await setSession(null);
      await dispatch({ type: "LOGOUT" });
    }
  };

  // const resetPassword = () => {};

  // const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
