import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import LinkItem from "src/pages/main/MainPage/LinkItem";
import { compareArray, getInObject } from "src/utils/PandaUtils";
import PlayListActionGroup from "src/pages/main/PlayListActionGroup";
import { setPadKey } from "src/redux/slices/mainPage";

export default function PadTab() {
  const [checkedValues, setCheckedValues] = useState([]);
  const dispatch = useDispatch();
  const { padTableList, showTableKey } = useSelector((state) => state.mainPage);

  const onCheck = (path) => {
    let index = -1;
    let contained = false;
    for (let length = 1; length < path.length; length++) {
      for (let i = 0; i < checkedValues.length; i++) {
        if (compareArray(checkedValues[i], path.slice(0, length)))
          contained = true;
      }
    }
    if (!contained) {
      for (let [i, keys] of checkedValues.entries()) {
        if (compareArray(keys, path)) index = i;
      }
    }
    if (index === -1) {
      setCheckedValues([...checkedValues, path]);
    } else {
      setCheckedValues(
        checkedValues.filter(
          (element) => !compareArray(path, element.slice(0, path.length))
        )
      );
    }
  };

  const getListInObject = (object) => {
    let array = object.child;
    let keys = Object.keys(object);
    for (let key of keys) {
      if (key !== "child") {
        array = array.concat(getListInObject(object[key]));
      }
    }
    return array;
  };

  const getSelectedItems = useMemo(() => {
    let result = [];
    for (const path of checkedValues) {
      result = result.concat(getListInObject(getInObject(padTableList, path)));
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValues]);

  const sortedPadTabKeyList = useMemo(() => {
    return Object.keys(padTableList).sort();
  }, [padTableList]);

  return (
    <Stack spacing={3} className="pad-tab">
      <PlayListActionGroup
        disabled={checkedValues.length === 0}
        selectedFileList={getSelectedItems}
      />

      <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
        {sortedPadTabKeyList.map((key, idx) => (
          <LinkItem
            key={idx}
            title={key}
            linkInfo={padTableList[key]}
            keyArray={[]}
            onClick={(keyArray) => dispatch(setPadKey(keyArray))}
            selected={showTableKey}
            onCheck={onCheck}
            checkedValues={checkedValues}
            checkable
          />
        ))}
      </List>
    </Stack>
  );
}
