import "./style.scss";

import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { DataTable } from "src/components";
import DetailItem from "./DetailItem";
import { reOrder } from "src/utils/reOrder";
import { useDispatch, useSelector } from "src/redux/store";
import {
  reorderPlayList,
  addPlayList,
  removePlayList,
  getProgramTableData,
  getCurrentItem, addItems, addItemsWithName, getViewCurrentItem,
} from "src/redux/slices/mainPage";
import useAlertModal from "src/hooks/useAlertModal";
import { getTodayDate } from "src/utils";
import useAuth from "src/hooks/useAuth";
import { NotificationManager } from "react-notifications";

// const getListStyle = (isDraggingOver) => ({});

export default function ExpandedDetailTable({
  list,
  isDefault,
  handleChangeIndex, reorderLoading
}) {
  const [items, setItems] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const mainPage = useSelector((state) => state.mainPage);
  const { channel, selectedDate } = mainPage;
  const { user } = useAuth();
  const { show: showAlert } = useAlertModal();

  const dispatch = useDispatch();

  useEffect(() => {
    setItems(list.items);
    setSelectedList([]);
  }, [list]);

  const removeSubItem = (items) => {
    showAlert({
      name: "delete-single-program-row-sub-item",
      text: "Are you sure you want to delete?",
      onAccept: async () => {
        const response = await dispatch(removePlayList({ ...list, items }));
        if (response.status === "success") {
          NotificationManager.success("Successfully deleted");
        }
        await dispatch(getProgramTableData(mainPage.channel, getTodayDate()));
      },
    });
  };

  const onDragEnd = async (result) => {
    if (result.destination.index === result.source.index) {
      return;
    } else {
      const reorderedItems = reOrder(
        items,
        result.source.index,
        result.destination.index
      );
      setItems(reorderedItems);
    }
  };

  const onDragStart = (result) => {
    // console.log("Start: ", result);
  };

  const saveReOrder = async () => {
    if (list.items !== items) {
      if (isDefault) {
        reorderLoading(true)
        let arrItemZero = [];
        for (const [index, item] of items.entries()) {
          arrItemZero.push({
            name: item.item,
            duration: item.duration,
            item: item.item,
            startfrom: item.startfrom,
            title: item.title,
          })
        }
        const response = await dispatch(addItemsWithName(mainPage.channel, 'current', 0, 0, arrItemZero));
        if (response && response.status === 'success') {
          await dispatch(removePlayList({ ...list, items: list.items }));
          await dispatch(getProgramTableData(channel, selectedDate));
        }
        reorderLoading(false)
        // await dispatch(
        //   reorderPlayList({
        //     playlistID: list.id,
        //     items,
        //     channel: mainPage.channel,
        //   })
        // );
        // await dispatch(getProgramTableData(channel, selectedDate));
        // handleChangeIndex(null);
      } else {
        await dispatch(
          addPlayList({
            channel: mainPage.channel,
            items,
            title: list.title,
            shuffle: list.shuffle,
            priority: list.priority,
            time: list.startTime,
          })
        );
        await dispatch(removePlayList(list));
        await dispatch(getProgramTableData(channel, selectedDate));
        handleChangeIndex(null);
      }
    }
  };

  const deleteSelectedLists = async () => {
    if (selectedList.length > 0) {
      removeSubItem(selectedList);
    }
  };

  const handleSelectItem = (item) => (evt) => {
    if (evt.target.checked) {
      setSelectedList(selectedList.concat(item));
    } else {
      let index = selectedList.indexOf(item.id);
      if (index !== -1) {
        setSelectedList(selectedList.splice(index, 1));
        setSelectedList(selectedList.filter((i, idx) => idx !== index));
      }
    }
  };

  const handleClickItem = (item, idx) => {
    setClickedIndex(idx);
    dispatch(
      getCurrentItem({
        channel,
        user,
        currentItemId: item.id,
        itemName: item.item,
      })
    );
  };

  const { channels } = mainPage;
  let headers = [
    {
      name: (
        <Stack direction="row" spacing={1}>
          <Tooltip title="Save" placement="top">
            <IconButton onClick={saveReOrder}>
              <SaveIcon sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delte" placement="top">
            <IconButton onClick={deleteSelectedLists}>
              <DeleteIcon sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
      width: "100px",
    },
    { name: "", align: "center" },
    { name: "ID", align: "center" },
    { name: "Path", align: "center" },
    { name: "Duration", align: "center" },
    { name: "Action", align: "center" },
  ];
  return (
    <DataTable
      header={headers}
      config={{
        headerColor: "black",
        headerTextColor: "white",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId={`${list.id}`}>
          {(provided, snapshot) => (
            <tbody
              ref={provided.innerRef}
              {...provided.droppableProps}
              // style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, idx) => (
                <DetailItem
                  key={item.id}
                  index={idx}
                  clickedIdx={clickedIndex}
                  item={item}
                  channels={channels}
                  handleSelectItem={handleSelectItem}
                  onClick={() => handleClickItem(item, idx)}
                  onRemove={() => removeSubItem([item])}
                />
              ))}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </DataTable>
  );
}
