import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";
import CONFIG from "src/config";

const initialState = {
  isLoading: false,
  error: "",
  status: false,
};

const slice = createSlice({
  name: "youtube",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setLiveStatus(state, action) {
      state.isLoading = false;
      state.status = !state.status;
    },

    successStatusAction(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },
  },
});

export function setYoutubeLiveStatus(channel, streamKey, action) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${CONFIG.apiUrl}/youtube`, {
        action,
        channel,
        streamKey,
      });
      if (action !== "status") {
        if (response.data.success) {
          dispatch(slice.actions.setLiveStatus());
        }
      } else {
        dispatch(slice.actions.successStatusAction(response.data.success));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export default slice.reducer;
