import { useState, useEffect } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import { Radio } from "src/components";
import { useDispatch, useSelector } from "src/redux/store";
import { setOverlay } from "src/redux/slices/overlay";

export default function FormatComponent() {
  const { overlay } = useSelector((state) => state.overlay);
  const [value, setValue] = useState(overlay.clock || "HH:mm");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlay({ clock: value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <RadioGroup
      name="format"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      <Radio value="HH:mm" label="HH:mm" />
      <Radio value="HH:mm:ss" label="HH:mm:ss" />
    </RadioGroup>
  );
}
