import "./style.scss";
import React from "react";

function PlayButton({ playing, onClick }) {
  return (
    <div
      className={`panda-play-button${playing ? " playing" : ""}`}
      onClick={onClick}
    >
      {playing ? (
        <i className="fa fa-stop-circle" />
      ) : (
        <i className="fa fa-play-circle" />
      )}
    </div>
  );
}
export default React.memo(PlayButton);
