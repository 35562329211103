import moment from "moment";
import { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CachedIcon from "@mui/icons-material/Cached";
import LayersClearRoundedIcon from "@mui/icons-material/LayersClearRounded";
import TvIcon from "@mui/icons-material/Tv";
import CameraIcon from "@mui/icons-material/Camera";
import ActionsIcon from "@mui/icons-material/MoreVert";

import { DataTable } from "src/components";
import CheckBox from "src/components/Checkbox";
import CalendarModal from "../Modals/CalendarModal";
import {
  addPlayList,
  getProgramTableData,
  removePlayList,
  setSelectedDate,
} from "src/redux/slices/mainPage";
import { useDispatch, useSelector } from "src/redux/store";
import { getTodayDate, makeTimeDuration, msToTime, sleep } from "src/utils";
import ExpandRow from "./ExpandRow";
import ExpandedDetailTable from "./ExpandedDetailTable";
import useAlertModal from "src/hooks/useAlertModal";
import useMainModal from "src/hooks/useMainModal";
import MainLoadingScreen from "src/components/Loading/MainLoadingScreen";
import { NotificationManager } from "react-notifications";
import useAuth from "src/hooks/useAuth";
import {Menu} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function ProgramTabPanel({ search = "" }) {
  const { user } = useAuth();
  const [selectedList, setSelectedList] = useState([]);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isReorder, setIsReorder] = useState(false);
  const showMainModal = useMainModal();
  const dispatch = useDispatch();
  const { show: showAlert } = useAlertModal();
  const mainPage = useSelector((state) => state.mainPage);
  const {
    selectedDate,
    defaultChannelData,
    channels,
    programTableList,
    channel,
  } = mainPage;
  const [dateInCalendarModal, setDateInCalendarModal] = useState(selectedDate);

  const endDate = moment(selectedDate).add(1, "days").valueOf();
  const defaultPlayListId = defaultChannelData[0].defaultPlaylist.id;
  let currentPlaylistId;
  let currentPlaylistVod;
  if (channels.schedulePlaylist) {
    currentPlaylistId = channels.schedulePlaylist.id;
    currentPlaylistVod = channels.scheduleItem.vod;
  }

  const handleSelectAll = (evt) => {
    if (evt.target.checked) {
      setSelectedList(getPlayListToRender());
    } else {
      setSelectedList([]);
    }
  };

  const validatePlayListToHandle = () => {
    const playListToHandle = selectedList.filter(
      (i) => i.id !== defaultPlayListId
    );
    return playListToHandle;
  };

  const handleDeleteItems = async () => {
    const items = validatePlayListToHandle();
    if (items.length > 0) {
      showAlert({
        text: 'Are you sure you want to delete?',
        onAccept: async () => {
          // for await (const pl of items) {
          //   await dispatch(removePlayList(pl));
          //   await sleep(2000);
          // }
          // NotificationManager.success("Successfully deleted");
          await Promise.all(
            items.map(async (item) => {
              await dispatch(removePlayList(item));
            })
          )
            .then(async (res) => {
              setSelectedList([]);
              NotificationManager.success("Successfully deleted");
              // await dispatch(getProgramTableData(channel, getTodayDate()));
            })
            .catch((err) => {
              console.log(err);
            });
        },
        body: "Are you sure you want to delete?",
        name: "delete-selected-program-rows",
      });
    }
  };

  const removeItem = (item) => {
    showAlert({
      name: "delete-single-program-row",
      text: "Are you sure you want to delete?",
      onAccept: async () => {
        const response = await dispatch(removePlayList(item));
        if (response.status === "success") {
          NotificationManager.success("Successfully deleted");
        }
      },
    });
  };

  const activeList = (index, item) => {
    setSelectedIndex(index);
  };

  const handleRowCheck = (item) => (evt) => {
    if (evt.target.checked) {
      setSelectedList(selectedList.concat(item));
    } else {
      const index = selectedList.indexOf(item);
      if (index !== -1) {
        setSelectedList(selectedList.filter((i) => i.id !== item.id));
      }
    }
  };

  const makeNewCopyDate2 = (date) => {
    const items = validatePlayListToHandle();
    const futureDate = moment(dateInCalendarModal);
    if (items.length > 0) {
      const selectedPlayListStartTime = moment(date);
      const diffAsDay = futureDate.diff(selectedPlayListStartTime, "days") + 1;
      const newStartTime = selectedPlayListStartTime.add(diffAsDay, "days");
      const newStartTimeAsMileSec = moment(newStartTime).valueOf();
      return newStartTimeAsMileSec;
    }
    return futureDate.valueOf();
  };

  const makeNewCopyDate = (date) => {
    const items = validatePlayListToHandle();
    const futureDate = moment(dateInCalendarModal);
    if (items.length > 0) {
      const selectedPlayListStartTime = moment(date);
      const diffAsDay = isSameDay(selectedPlayListStartTime, futureDate) ? futureDate.diff(futureDate, "days") + 1 : futureDate.diff(futureDate, "days");
      const newStartTime = futureDate.add(diffAsDay, "days");
      const newStartTimeAsMileSec = moment(newStartTime).valueOf();
      return newStartTimeAsMileSec;
    }
    return futureDate.valueOf();
  };

  const isSameDay = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    // Compare year, month, and day
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
  }

  const handleCopyAction = async () => {
    setShowCopyModal(false);
    const items = validatePlayListToHandle();
    if (items.length > 0) {
      showAlert({
        name: "playlist-copy",
        text: "Are you sure you want to copy?",
        onAccept: async () => {
          await Promise.all(
            items.map(async (pl) => {
              await dispatch(
                addPlayList({
                  channel,
                  items: pl.items,
                  title: pl.title + "-C",
                  shuffle: pl.shuffle,
                  priority: pl.priority,
                  time: makeNewCopyDate(pl.startTime),
                })
              );
            })
          )
            .then(async (res) => {
              setSelectedList([]);
              await dispatch(getProgramTableData(channel, getTodayDate()));
              await dispatch(
                setSelectedDate(moment(dateInCalendarModal).valueOf())
              );
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    }
  };

  const handleCopyModalStatus = async () => {
    if (validatePlayListToHandle()) {
      let itemToCopy = validatePlayListToHandle()
      console.log(itemToCopy)
      let isLivePlayList = null // if vod === false
      itemToCopy.forEach(item => {
        item.items.find(subItem => {
          if (subItem.vod === false) {
            isLivePlayList = subItem
          }
        })
      })
      if (isLivePlayList) {
        NotificationManager.error("You cannot copy a live.");
        return
      }
      setShowCopyModal(true);
    }
  };

  const handleReload = async () => {
    setLoading(true);
    setSelectedList([]);
    await dispatch(getProgramTableData(channel, getTodayDate()));
    setLoading(false);
  };

  const handleRemoveOldSchedule = async () => {
    showAlert({
      name: "delete-old-schedule",
      text: "Are you sure you want to delete the schedule older than 7 days?",
      onAccept: async () => {
        const oldPlaylists = programTableList.filter((i) => {
          const minTime = getTodayDate() - 86400 * 1000 * 7;
          const maxTime = getTodayDate() - 86400 * 1000 * 187;
          // console.log(
          //   maxTime,
          //   i.startTime,
          //   minTime,
          //   i.title,
          //   new Date(minTime),
          //   new Date()
          // );
          if (i.startTime < minTime && i.startTime > maxTime) {
            return true;
          } else {
            return false;
          }
        });
        // console.log("Olds: ", oldPlaylists);
        for await (const pl of oldPlaylists) {
          await dispatch(removePlayList(pl));
          await sleep(2000);
        }
        NotificationManager.success("Successfully deleted");
      },
    });
  };

  const handleUpdatePlaylist = async (pl) => {
    showMainModal({
      modalName: "add-time",
      status: true,
      items: pl.items,
      playlistId: pl.id,
      deletePL: true,
    });

    // await dispatch(removePlayList(pl));
    await dispatch(getProgramTableData(mainPage.channel, getTodayDate()));
  };

  const getPlayListToRender = () => {
    let listToRender = [];
    if (programTableList.length === 0) {
      listToRender = listToRender.concat(defaultChannelData[0].defaultPlaylist);
    } else {
      programTableList.forEach((i) => {
        if (i.title.toLowerCase().includes(search.toLocaleLowerCase())) {
          const programLive = i.items.filter((item) => item.vod === false); // is live program object
          if (
            (i.startTime > selectedDate &&
            i.startTime < endDate &&
            i.items.length > 0)
            ||
            (programLive.length > 0 && i.startTime < selectedDate && i.stopTime >= selectedDate)
          ) {
            listToRender.push(i);
          } else if (i.id === defaultPlayListId) {
            listToRender.unshift(i);
          }
        }
      });
    }
    const sortedPlaylist = listToRender
      .slice(1)
      .sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
    sortedPlaylist.unshift(listToRender[0]);
    return sortedPlaylist;
  };

  const renderTable = () => {
    if (getPlayListToRender().length > 0) {
      return getPlayListToRender().map((list, idx) => renderRow(list, idx));
    }
  };

  const openOverlay = (key, program) => {
    const warningExist = warningMsg();
    if (warningExist) {
      return false;
    }
    showMainModal({ modalName: key, status: true, program });
  };
  const warningMsg = () => {
    if (!user.overlay) {
      NotificationManager.warning(
          "You don't have Overlay Option. For more details, please contact our Customer Service at support@acan.email.",
          "Warning",
          3000
      );
      return true;
    }
    return false;
  };

  const reorderLoading = status => {
    setIsReorder(status)
  }

  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    let open = Boolean(anchorEl);
    console.log("OPEN=",open)
    console.log("ANCHOR",Boolean(anchorEl))

    const dropDownClick = (event) => {
      console.log(event.currentTarget)
      setAnchorEl(event.currentTarget)
    }

    return(
        <Fragment>
          <IconButton
              aria-haspopup="true"
              onClick={event => {
                dropDownClick(event);
              }}
          >
            <ActionsIcon />
          </IconButton>
          <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => openOverlay("add-new-overlay-drawer", props.list)}>Add Overlay</MenuItem>
            <MenuItem onClick={
              props.list?.items[0].vod
                  ? () => handleUpdatePlaylist(props.list)
                  : () =>
                      showMainModal({
                        modalName: "go-live-modal",
                        status: true,
                        items: props.list.items,
                        playlistId: props.list.id,
                      })
            }>
              Edit time
            </MenuItem>
            <MenuItem onClick={() => {
              removeItem(props.list);
            }}>Delete</MenuItem>
          </Menu>

        </Fragment>
    )
  }

  const renderRow = (list, idx) => {
    return (
        list ? (<ExpandRow
            key={idx}
            list={list}
            type={list.id === defaultPlayListId ? "Default" : "Common"}
            selected={list.id === currentPlaylistId}
            arrowAble={
                selectedDate === getTodayDate() && currentPlaylistId === list.id
            }
            vod={currentPlaylistVod}
            onClick={() => activeList(idx)}
            expandIconcolor={idx === selectedIndex ? "white" : "primary"}
            className={idx === selectedIndex ? "active" : ""}
            row={
              <Fragment>
                <td className="center">
                  <CheckBox
                      color={idx === selectedIndex ? "white" : ""}
                      checked={selectedList.includes(list)}
                      onChange={handleRowCheck(list)}
                  />
                </td>
                <td className="center desktop">
                  {makeTimeDuration(list.startTime)}
                </td>
                <td className="center desktop">
                  {makeTimeDuration(list.stopTime)}
                </td>
                <td className="center">
                  <div className="wrap-content">{list.title}</div>
                </td>
                <td className="center desktop">
                  {msToTime(list.stopTime - list.startTime)}
                </td>
                <Stack
                    component="td"
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                  {list.id !== defaultPlayListId && (
                      <Fragment>
                        {/*<IsolatedMenu list={list} idx={idx} />*/}


                        <IconButton title="Add an Overlay" onClick={() => openOverlay("add-new-overlay-drawer", list)}>
                          <CameraIcon sx={{ color: "black" }} />
                        </IconButton>
                        <IconButton
                            onClick={
                              list.items[0].vod
                                  ? () => handleUpdatePlaylist(list)
                                  : () =>
                                      showMainModal({
                                        modalName: "go-live-modal",
                                        status: true,
                                        items: list.items,
                                        playlistId: list.id,
                                        list: list,
                                      })
                            }
                        >
                          {list.items[0].vod ? (
                              <MoreTimeIcon sx={{ color: "black" }} />
                          ) : (
                              <TvIcon sx={{ color: "black" }} />
                          )}
                        </IconButton>
                        <IconButton
                            onClick={() => {
                              removeItem(list);
                            }}
                        >
                          <HighlightOffIcon sx={{ color: "black" }} />
                        </IconButton>
                      </Fragment>
                  )}
                </Stack>
              </Fragment>
            }
            detail={
              <ExpandedDetailTable
                  list={list}
                  isDefault={list.id === defaultPlayListId}
                  handleChangeIndex={setSelectedIndex}
                  reorderLoading={reorderLoading}
              />
            }
        />): (<tr>
          <td colSpan="8">
            No programs found.
          </td>
        </tr>)
    );
  };

  const programsHeaders = [
    { name: "", width: "50px", align: "center" },
    {
      name: (
        <CheckBox
          onChange={handleSelectAll}
          checked={
            selectedList.length > 0 &&
            selectedList.length === getPlayListToRender().length
          }
        />
      ),
      width: "50px",
      align: "center",
    },
    { name: "Start", width: "100px", align: "center", class: "desktop" },
    { name: "End", width: "100px", align: "center" },
    { name: "Program", width: "auto", align: "center" },
    { name: "Duration", width: "100px", align: "center", class: "desktop" },
    { name: "Actions", width: "150px", align: "center" },
  ];

  if (loading || isReorder) {
    return <MainLoadingScreen />;
  }

  return (
    <Stack spacing={1} flexGrow={1}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Tooltip title="Reload" placement="top">
          <IconButton onClick={handleReload}>
            <CachedIcon sx={{ color: "#8EC044" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Old Schedule" placement="top">
          <IconButton onClick={handleRemoveOldSchedule}>
            <LayersClearRoundedIcon sx={{ color: "#8EC044" }} />
          </IconButton>
        </Tooltip>

        <Box flexGrow={1} />

        <Tooltip title="Copy" placement="top">
          <IconButton onClick={handleCopyModalStatus}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <IconButton onClick={handleDeleteItems}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <DataTable
        header={programsHeaders}
        selected={selectedIndex}
        config={{
          headerColor: "black",
          headerTextColor: "white",
        }}
      >
        {renderTable()}
      </DataTable>

      {showCopyModal && (
        <CalendarModal
          open={showCopyModal}
          onClose={() => setShowCopyModal(false)}
          showTime={validatePlayListToHandle().length === 1}
          defaultStartTime={validatePlayListToHandle()[0].startTime}
          selectedDate={dateInCalendarModal}
          handleCopyAction={handleCopyAction}
          handleDateChange={(value) => {
            setDateInCalendarModal(value.valueOf());
          }}
        />
      )}
    </Stack>
  );
}
