import "./style.scss";

import { Fragment } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";

import { Modal, ImageIcon, FaIconButton } from "src/components";

import { CustomScrollContainer } from "src/container";
import { useEffect, useState } from "react";
import { useDispatch } from "src/redux/store";
import { useSelector } from "react-redux";
import useAuth from "src/hooks/useAuth";
import {
  getOverlayList,
  setOnOffOverlay,
  removeOverlay,
  setOverlay,
  addOverlay, toggleLockOverlay,
} from "src/redux/slices/overlay";
import useMainModal from "src/hooks/useMainModal";
import useAlertModal from "src/hooks/useAlertModal";
import { getSubStringFromFirst } from "src/utils";
import { NotificationManager } from "react-notifications";
import LockIcon from "@mui/icons-material/Lock";
import {LockOpen} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import {pink} from "@mui/material/colors";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
export default function OverlayModal({ open, onClose }) {
  const [tabName, setTabName] = useState(0);
  const [loading, setLoading] = useState(true);
  const [globalChecked, setGlobalChecked] = useState(false);

  const dispatch = useDispatch();
  const { user } = useAuth();
  const showMainModal = useMainModal();
  const { show: showAlert } = useAlertModal();

  const { overlayActiveList, overlayWaitingList, overlayStagingList } =
    useSelector((state) => state.overlay);
  const mainPage = useSelector((state) => state.mainPage);
  const {
    programTableList,
  } = useSelector((state) => state.mainPage);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    await dispatch(
      getOverlayList({ channel: mainPage.channel, overlayType: "active" })
    );
    await dispatch(
      getOverlayList({ channel: mainPage.channel, overlayType: "waiting" })
    );
    await dispatch(
      getOverlayList({ channel: mainPage.channel, overlayType: "staging" })
    );
    setLoading(false);
    if (overlayActiveList.length > 0) {
      setGlobalChecked(true);
    }
  };

  const handleSetOverlay = (ol, type) => {
    dispatch(setOverlay(ol));
    showMainModal({
      modalName: "overlay-edit-modal",
      status: true,
      olType: type,
    });
    onClose();
  };

  const clear = () => {
    onClose();
  };

  const handleSwitchChange = (e) => {
    setGlobalChecked(e.target.checked);
    dispatch(setOnOffOverlay(mainPage.channel, e.target.checked));
  };

  const onOpenAddItem = () => {
    onClose();
    showMainModal({ modalName: "add-new-overlay-drawer", status: true });
  };

  const sendToOnAir = (overlayId) => {
    showAlert({
      name: "send-to-onair-selected-overlay-item",
      text: "Are you sure you want to send this overlay to OnAir?",
      onAccept: async () => {
        const response = await dispatch(
          addOverlay({
            channel: mainPage.channel,
            overlay: overlayStagingList.find((i) => i.id === overlayId),
            overlayType: "active",
          })
        );
        if (response.status === "success") {
          NotificationManager.success("Successfully added");
        } else {
          NotificationManager.error("Failed");
        }
        await getData();
      },
    });
  };

  const deleteOverlay = async (overlayId, type) => {
    showAlert({
      name: "delete-selected-overlay-item",
      text: "Are you sure you want to delete?",
      onAccept: async () => {
        await dispatch(
          removeOverlay({
            channel: mainPage.channel,
            overlayId: overlayId,
            overlayType: type,
          })
        );
        await getData();
      },
    });
  };

  const toggleLock = async (overlayId) => {
    await dispatch(
        toggleLockOverlay({
          channel: mainPage.channel,
          overlayId: overlayId,
        })
    );
    await getData();
  };

  const getActiveIcon = (ao) => {
    if (ao.clock) {
      return <AccessTimeIcon sx={{ color: "#8EC044" }} />;
    } else if (ao.image) {
      return <ImageIcon sx={{ color: "#8EC044" }} />;
    } else {
      return <FormatColorTextIcon sx={{ color: "#8EC044" }} />;
    }
  };

  const getActiveContent = (ao) => {
    if (ao.clock) {
      return <ListItemText>{ao.clock}</ListItemText>;
    } else if (ao.text) {
      return <ListItemText>{getSubStringFromFirst(ao.text, 100)}
        {/*{overlayStagingList.find(el => el.id == ao.id && el.programId > 0) ? ` | Program ${programTableList.find(p => p.id == (overlayStagingList.find(o => o.id == ao.id) ).programId )?.title}` : ''}*/}
        {overlayStagingList.find(el => el.id === ao.id && el.programId > 0) ?
              <span style={{color: '#8ec044'}}> | Program {programTableList.find(p => p.id === (overlayStagingList.find(o => o.id === ao.id) ).programId )?.title} </span>
             :
            ''}
      </ListItemText>;
    } else {
      return (
        <img
          src={ao.image}
          alt="Base64Img"
          style={{
            width: "auto",
            height: "32px",
          }}
        />
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={clear}
      width="80vw"
      loading={loading}
      className="overlay-modal"
    >
      {!loading && (
        <Fragment>
          <Tabs
            value={tabName}
            onChange={(_, newValue) => setTabName(newValue)}
            aria-label="overlay-staging-tab"
          >
            <Tab label="Overlay List" />
            <Tab label="Overlay Template" />
          </Tabs>
          <TabPanel value={tabName} index={0}>
            <div className="content">
              <CustomScrollContainer>
                <Stack direction="row" spacing={2}>
                  <Box sx={{ flex: "1 1 0" }}>
                    <Stack
                      px={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ backgroundColor: "#8EC044", height: 40 }}
                    >
                      <Typography>On Air</Typography>
                      <Switch
                        checked={globalChecked}
                        onChange={handleSwitchChange}
                        name="checkedA"
                      />
                    </Stack>
                    <List>
                      {overlayActiveList.map((ao, idx) => (
                        <ListItem
                          key={idx}
                          disablePadding
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => deleteOverlay(ao.id, "active")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        >
                          <ListItemButton
                            onClick={() => handleSetOverlay(ao, "active")}
                          >
                            <ListItemIcon>{getActiveIcon(ao)}</ListItemIcon>
                            {getActiveContent(ao)}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                  <Box sx={{ flex: "1 1 0" }}>
                    <Stack
                      px={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ backgroundColor: "#8EC044", height: 40 }}
                    >
                      <Typography>Upcomming</Typography>
                    </Stack>

                    <List>
                      {overlayWaitingList.map((wo, idx) => (
                        <ListItem key={idx} disablePadding>
                          <ListItemButton
                            onClick={() => handleSetOverlay(wo, "active")}
                          >
                            {getActiveIcon(wo)}
                            {getActiveContent(wo)}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Stack>
              </CustomScrollContainer>
            </div>
          </TabPanel>
          <TabPanel value={tabName} index={1}>
            <div className="content">
              <CustomScrollContainer>
                <List>
                  {overlayStagingList.map((so, idx) => (
                    <ListItem
                      key={idx}
                      disablePadding
                      secondaryAction={
                        <Stack direction="row" spacing={2}>
                          <IconButton
                            edge="end"
                            aria-label="Send to OnAir"
                            onClick={() => sendToOnAir(so.id)}
                          >
                            <SendIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => deleteOverlay(so.id, "staging")}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                              edge="end"
                              aria-label="lock"
                              onClick={() => toggleLock(so.id)}
                          >
                            {so.locked ?
                                <Tooltip title="Unlock overlay" placement="right-start">
                                  <LockIcon sx={{ color: pink[500] }} />
                                </Tooltip>
                                :
                                <Tooltip title="Lock overlay" placement="right-start">
                                <LockOpen />
                                </Tooltip>
                            }
                          </IconButton>
                        </Stack>
                      }
                    >
                      <ListItemButton
                        onClick={() => handleSetOverlay(so, "staging")}
                      >
                        <ListItemIcon>{getActiveIcon(so)}</ListItemIcon>
                        {getActiveContent(so)}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </CustomScrollContainer>
            </div>
          </TabPanel>
          <div className="notify">
            <p>{`${
              overlayActiveList.length + overlayWaitingList.length
            } overlays used in ${user.overlay_count}. Left: ${
              user.overlay_count -
              overlayActiveList.length -
              overlayWaitingList.length
            }.`}</p>
          </div>
          <div className="actions">
            <FaIconButton
              icon="fa-plus-circle"
              size="2rem"
              onClick={onOpenAddItem}
            />
          </div>
        </Fragment>
      )}
    </Modal>
  );
}
