import "./style.scss";

import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LoginImage from "src/assets/image/login.png";
import LoginLogo from "src/assets/image/login_logo.png";

import { EmailValidation } from "src/utils";
import useAuth from "src/hooks/useAuth";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (EmailValidation(email) && password) {
      await login(email, password);
    }
  };

  return (
    <div className="login-page">
      <Stack
        direction="row"
        sx={{
          width: "70%",
          background: "rgba(255, 255, 255, 0.76)",
          boxShadow: "0px 0px 34px rgba(0, 0, 0, 0.15)",
          borderRadius: "34px",
        }}
        className="login-component"
      >
        <Box
          p={5}
          sx={{
            backgroundColor: "#B7D251",
            borderRadius: "36px",
            zIndex: 100,
            flex: "1 1 auto",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className="description"
        >
          <img src={LoginImage} alt="features" style={{ maxWidth: "100%" }} />
        </Box>
        <Stack
          p={5}
          spacing={3}
          justifyContent="center"
          noValidate
          autoComplete="off"
          component="form"
          onSubmit={handleSubmit}
          sx={{ zIndex: 100, flex: "1 1 auto" }}
        >
          <Box display="flex" justifyContent="center">
            <img src={LoginLogo} alt="logo" width="120" />
          </Box>
          <Typography variant="h4" textAlign="center">
            Connect to aCAS Panel
          </Typography>
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              background: "#FFFFFF",
              zIndex: 100,
              boxShadow: "0px 0px 22px rgba(0, 0, 0, 0.12)",
              borderRadius: "20px",
              height: 70,
              width: "100%",
            }}
          >
            <IconButton sx={{ p: "10px" }}>
              <MailIcon sx={{ color: "#B7D251" }} />
            </IconButton>
            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
            <InputBase
              required
              value={email}
              error={!EmailValidation(email)}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Enter your email"
              inputProps={{ "aria-label": "Enter your Email" }}
            />
          </Paper>
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              background: "#FFFFFF",
              zIndex: 100,
              boxShadow: "0px 0px 22px rgba(0, 0, 0, 0.12)",
              borderRadius: "20px",
              height: 70,
              width: "100%",
            }}
          >
            <IconButton
              sx={{ p: "10px" }}
              onClick={() => setShowPassword((prevState) => !prevState)}
            >
              {!showPassword ? (
                <LockIcon sx={{ color: "#B7D251" }} />
              ) : (
                <LockOpenIcon sx={{ color: "#B7D251" }} />
              )}
            </IconButton>
            <Divider sx={{ height: 20, m: 0.5 }} orientation="vertical" />
            <InputBase
              required
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Enter your Password"
              inputProps={{
                "aria-label": "Enter your Password",
              }}
            />
          </Paper>
          <Box flexGrow={1}>
            <Button
              type="submit"
              sx={{
                width: "100%",
                backgroundColor: "#B7D251",
                boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.16)",
                borderRadius: "20px",
                textTransform: "none",
                color: "black",
                "&:hover": {
                  backgroundColor: "#B7D251",
                },
              }}
              disabled={!EmailValidation(email)}
            >
              <Typography variant="h4">Sign in</Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
    </div>
  );
}
