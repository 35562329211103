import "./style.scss";

import { useState } from "react";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";

import { CustomScrollContainer } from "src/container";
import { Modal, Button, CheckBox, Radio, CustomSelect } from "src/components";
import MuiInput from "src/components/InputText/MuiInput";
import themeColor from "src/constants/themeColor";
import { useDispatch, useSelector } from "src/redux/store";
import {
  addPlayList,
  getProgramTableData,
  removePlayList,
  setSelectedFileList,
} from "src/redux/slices/mainPage";
import { getTodayDate } from "src/utils";

const getMaxStartTime = (programTableList) => {
  const startTime = moment().startOf("day").valueOf();
  const endTime = moment().endOf("day").valueOf();
  const todayData = [];
  programTableList.slice(1).forEach((o) => {
    if (o.stopTime > startTime && o.stopTime <= endTime) {
      todayData.push(o.stopTime);
    }
  });
  if (todayData.length > 0) {
    const maxVal = Math.max(...todayData);
    return maxVal;
  }
  return moment().valueOf();
};

export default function AddMediaModal({
  open,
  onClose,
  playlistId,
  items,
  hasToDelete,
}) {
  const { channel, programTableList } = useSelector((state) => state.mainPage);
  const playlist = programTableList.find((i) => i.id === playlistId) || {};
  const [state, setState] = useState({
    loading: false,
    repeat: "everyday",
    title: playlist.title || "My PlayList",
    toRepeat: false,
    position: "right",
    positionDate: playlist.startTime || getMaxStartTime(programTableList),
    repeatUntil: moment().valueOf(),
    days: [],
    shuffle: playlist.shuffle || false,
    priority: playlist.priority || 1,
  });

  const dispatch = useDispatch();

  const changeState = (data) => {
    setState({ ...state, ...data });
  };

  const selectDays = (day) => {
    let { days } = state;
    if (days.includes(day)) {
      days.splice(days.indexOf(day), 1);
    } else {
      days.push(day);
    }
    changeState({ days });
  };

  const changePositionDate = (positionDate) => {
    changeState({ positionDate });
    // const date = moment(positionDate).valueOf();
    // changeState((prevState) => ({
    //   positionDate: date,
    //   repeatUntil: date > prevState.repeatUntil ? date : prevState.repeatUntil,
    // }));
  };

  const changeRepeatUntil = (repeatUntil) => {
    const newRepeatUntil = moment(repeatUntil).valueOf();
    if (newRepeatUntil > state.positionDate)
      changeState({ repeatUntil: newRepeatUntil });
  };

  const changeToRepeat = (evt) => {
    changeState({ toRepeat: evt.target.checked });
  };

  const makeRequestData = async () => {
    changeState({ loading: true });
    const {
      repeat,
      positionDate,
      repeatUntil,
      days,
      toRepeat,
      title,
      shuffle,
      priority,
    } = state;

    const repeatDays = [];
    if (toRepeat) {
      let startDate = moment(positionDate);
      let endDate = moment(repeatUntil);
      while (startDate.valueOf() <= endDate.valueOf()) {
        if (repeat === "everyday") {
          repeatDays.push(startDate.valueOf());
        } else if (repeat === "every" && days.includes(startDate.day())) {
          repeatDays.push(startDate.valueOf());
        }
        startDate.add(1, "day");
      }
    } else {
      repeatDays.push(positionDate.valueOf());
    }

    await Promise.all(
      repeatDays.map(async (rd) => {
        await dispatch(
          addPlayList({
            channel,
            items,
            title,
            shuffle,
            priority,
            time: rd,
          })
        );
      })
    )
      .then(async (res) => {
        if (hasToDelete) {
          await dispatch(removePlayList({ id: playlistId, items }));
        }
        await dispatch(setSelectedFileList([]));
        await dispatch(getProgramTableData(channel, getTodayDate()));
      })
      .catch((err) => {
        console.log(err);
      });
    await clear();
  };

  const clear = async () => {
    await onClose();
  };

  const changeTitle = (evt) => changeState({ title: evt.target.value });

  const {
    repeat,
    positionDate,
    repeatUntil,
    days,
    toRepeat,
    loading,
    title,
    shuffle,
    priority,
  } = state;
  const disabledDays = repeat !== "every";
  let priorities = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <Modal
      open={open}
      onClose={clear}
      textColor="white"
      className="add-media-modal"
      color={themeColor["--primary-color"]}
      loading={loading}
    >
      <div className="subject">Add</div>
      <div className="content">
        <CustomScrollContainer color="white">
          <Stack spacing={2}>
            <MuiInput
              placeholder="Title"
              value={title}
              onChange={changeTitle}
              sx={{
                "& .MuiOutlinedInput-root.MuiInputBase-root": {
                  borderRadius: "4px !important",
                  border: "1px solid white !important",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                  {
                    color: "white !important",
                    width: "100%",
                    backgroundColor: "#8EC044 !important",
                  },
                width: "100%",
              }}
            />
            <div className="sub-title">Time</div>
            <DateTimePicker
              ampm={false}
              value={positionDate}
              inputFormat="MM/dd/yyyy HH:mm:ss"
              mask="__/__/____ __:__:__"
              onChange={changePositionDate}
              renderInput={(params) => (
                <MuiInput
                  {...params}
                  sx={{
                    "& .MuiOutlinedInput-root.MuiInputBase-root": {
                      borderRadius: "4px !important",
                      border: "1px solid white !important",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                      {
                        color: "white !important",
                        width: "100%",
                        backgroundColor: "#8EC044 !important",
                      },
                    width: "100%",
                  }}
                />
              )}
            />
            <CheckBox
              color="white"
              checked={toRepeat}
              onChange={changeToRepeat}
            >
              TO REPEAT
            </CheckBox>
            {toRepeat && (
              <Stack pl={4}>
                <div className="sub-title">REPEAT</div>
                <RadioGroup
                  name="repeat"
                  value={repeat}
                  onChange={(e) => changeState({ repeat: e.target.value })}
                >
                  <Radio color="white" value="everyday" label="Everyday" />
                  <Radio color="white" value="every" label="every" />
                </RadioGroup>
                <div className="select-day">
                  <div>
                    {[
                      { value: 1, label: "Monday" },
                      { value: 2, label: "Tuesday" },
                      { value: 3, label: "Wednesday" },
                    ].map((i, idx) => (
                      <CheckBox
                        key={idx}
                        color="white"
                        disabled={disabledDays}
                        checked={days.includes(i.value)}
                        onChange={() => selectDays(i.value)}
                      >
                        {i.label}
                      </CheckBox>
                    ))}
                  </div>
                  <div>
                    {[
                      { value: 4, label: "Thursday" },
                      { value: 5, label: "Friday" },
                      { value: 6, label: "Saturday" },
                      { value: 0, label: "Sunday" },
                    ].map((i, idx) => (
                      <CheckBox
                        key={idx}
                        color="white"
                        disabled={disabledDays}
                        checked={days.includes(i.value)}
                        onChange={() => selectDays(i.value)}
                      >
                        {i.label}
                      </CheckBox>
                    ))}
                  </div>
                </div>
                <div className="sub-title">REPEAT UNTIL</div>
                <DateTimePicker
                  ampm={false}
                  value={repeatUntil}
                  inputFormat="MM/dd/yyyy HH:mm:ss"
                  mask="__/__/____ __:__:__"
                  onChange={changeRepeatUntil}
                  renderInput={(params) => (
                    <MuiInput
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root.MuiInputBase-root": {
                          borderRadius: "4px !important",
                          border: "1px solid white !important",
                        },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                          {
                            color: "white !important",
                            width: "100%",
                            backgroundColor: "#8EC044 !important",
                          },
                        width: "100%",
                      }}
                    />
                  )}
                />
              </Stack>
            )}
            <CheckBox
              color="white"
              checked={shuffle}
              onChange={(evt) => changeState({ shuffle: evt.target.checked })}
            >
              Shuffle
            </CheckBox>

            <div className="sub-title">Priority</div>
            <CustomSelect
              color="white"
              textColor="white"
              items={priorities}
              value={priority}
              width="20%"
              onChange={(value) => changeState({ priority: value })}
            />
          </Stack>
        </CustomScrollContainer>
      </div>
      <div className="actions">
        <Button color="white" onClick={makeRequestData}>
          Add
        </Button>
      </div>
    </Modal>
  );
}
