import "./style.scss";

import { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import RadioGroup from "@mui/material/RadioGroup";

import { Radio, InputField, Select } from "src/components";
import { useSelector, useDispatch } from "src/redux/store";
import { setOverlay } from "src/redux/slices/overlay";

export default function PositionComponent() {
  const { overlay } = useSelector((state) => state.overlay);
  const [x, setX] = useState(overlay.position ? overlay.position.x : 0.05);
  const [y, setY] = useState(overlay.position ? overlay.position.y : 0.05);
  const [z, setZ] = useState(overlay.position ? overlay.position.z : 1);
  const [hUnit, setHUnit] = useState("percentage");
  const [vUnit, setVUnit] = useState("percentage");
  const [anchor, setAnchor] = useState(
    overlay.position ? overlay.position.anchor : "top-left"
  );
  const [presetPosition, setPresetPosition] = useState("Select One");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlay({ position: { ...overlay.position, x } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x]);

  useEffect(() => {
    dispatch(setOverlay({ position: { ...overlay.position, y } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [y]);

  useEffect(() => {
    dispatch(setOverlay({ position: { ...overlay.position, z: parseInt(z) } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [z]);

  useEffect(() => {
    dispatch(setOverlay({ position: { ...overlay.position, anchor } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor]);

  const changeType = (field, value) => {
    if (field === "h_unit") {
      if (value === "in_pixels") {
        setX(10);
      } else {
        setX(0.1);
      }
      setHUnit(value);
    } else {
      if (value === "in_pixels") {
        setY(10);
      } else {
        setY(0.1);
      }
      setVUnit(value);
    }
  };

  const onPresetPosition = (overlay_type) => (event) => {
    if (overlay_type === "image") {
      switch (event.target.value) {
        case "logo-top-left":
          setX(0.05);
          setY(0.05);
          setPresetPosition(event.target.value);
          break;
        case "logo-top-right":
          setX(0.8);
          setY(0.05);
          setPresetPosition(event.target.value);
          break;
        case "logo-bottom-left":
          setX(0.05);
          setY(0.8);
          setPresetPosition(event.target.value);
          break;
        case "logo-bottom-right":
          setX(0.8);
          setY(0.8);
          setPresetPosition(event.target.value);
          break;
        case "title-background":
          setX(0.8);
          setY(0.01);
          setPresetPosition(event.target.value);
          break;
        case "watermark":
          setX(0.5);
          setY(0.5);
          setPresetPosition(event.target.value);
          break;
        default:
          break;
      }
    }

    if (overlay_type === "default") {
      switch (event.target.value) {
        case "top-left":
          setX(0.05);
          setY(0.05);
          setPresetPosition(event.target.value);
          break;
        case "top-right":
          setX(0.8);
          setY(0.05);
          setPresetPosition(event.target.value);
          break;
        case "top-center":
          setX(0.5);
          setY(0.05);
          setPresetPosition(event.target.value);
          break;

        case "bottom-left":
          setX(0.05);
          setY(0.8);
          setPresetPosition(event.target.value);
          break;
        case "bottom-right":
          setX(0.8);
          setY(0.8);
          setPresetPosition(event.target.value);
          break;
        case "titling":
          setX(0.8);
          setY(0.03);
          setPresetPosition(event.target.value);
          break;
        case "newsbar-1":
          setX(0.05);
          setY(0.9);
          setPresetPosition(event.target.value);
          break;
        case "newsbar-2":
          setX(0.05);
          setY(0.85);
          setPresetPosition(event.target.value);
          break;
        case "watermark":
          setX(0.5);
          setY(0.5);
          setPresetPosition(event.target.value);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="position-component">
      <div className="title">
        Set position relative from the top left center of the video.
      </div>
      <div style={{ display: "flex", fontSize: "1.2rem", fontWeight: 600 }}>
        Presets
      </div>
      {overlay.image ? (
        <Select
          width="200px"
          value={presetPosition}
          onChange={onPresetPosition("image")}
        >
          <option value="select-one">Select One</option>
          <option value="logo-top-left">Logo Top Left</option>
          <option value="logo-top-right">Logo Top Right</option>
          <option value="top-center">Top Center</option>
          <option value="logo-bottom-left">Logo Bottom Left</option>
          <option value="logo-bottom-right">Logo Bottom Right</option>
          <option value="title-background">Title Background</option>
          <option value="watermark">Watermark</option>
        </Select>
      ) : (
        <Select
          width="200px"
          value={presetPosition}
          onChange={onPresetPosition("default")}
        >
          <option value="select-one">Select One</option>
          <option value="top-left">Top Left</option>
          <option value="top-right">Top Right</option>
          <option value="top-center">Top Center</option>
          <option value="bottom-left">Bottom Left</option>
          <option value="bottom-right">Bottom Right</option>
          <option value="titling">Titling</option>
          <option value="newsbar-1">News Bar 1</option>
          <option value="newsbar-2">News Bar 2</option>
          <option value="watermark">Watermark</option>
        </Select>
      )}
      <div className="edit-panel">
        <div className="items">
          <div className="sub-title">Horizontal</div>
          <RadioGroup
            name="horizontal"
            value={hUnit}
            onChange={(e) => changeType("h_unit", e.target.value)}
          >
            <Radio value="in_pixels" label="In Pixels" />
            <Radio value="percentage" label="Percentage" />
          </RadioGroup>
          {x > 1 && (
            <InputField
              width="150px"
              type="number"
              label="Pixels"
              value={x.toString()}
              onChange={(e) => setX(e.target.value)}
            />
          )}
          <Slider
            min={0}
            max={1}
            step={0.01}
            valueLabelDisplay="on"
            value={x}
            className="m-t-50"
            getAriaValueText={(value) =>
              hUnit === "in_pixels" ? `${value}px` : `${value * 100}%`
            }
            valueLabelFormat={(value) =>
              hUnit === "in_pixels" ? `${value}px` : `${value * 100}%`
            }
            onChange={(_, v) => setX(v)}
          />
        </div>
        <div className="items">
          <div className="sub-title">Vertical</div>
          <RadioGroup
            name="vertical"
            value={vUnit}
            onChange={(e) => changeType("v_unit", e.target.value)}
          >
            <Radio value="in_pixels" label="In Pixels" />
            <Radio value="percentage" label="Percentage" />
          </RadioGroup>
          {y > 1 && (
            <InputField
              width="150px"
              type="number"
              label="Pixels"
              value={y.toString()}
              onChange={(e) => setY(e.target.value)}
            />
          )}
          <Slider
            min={0}
            max={1}
            step={0.01}
            valueLabelDisplay="on"
            getAriaValueText={(value) =>
              vUnit === "in_pixels" ? `${value}px` : `${value * 100}%`
            }
            valueLabelFormat={(value) =>
              vUnit === "in_pixels" ? `${value}px` : `${value * 100}%`
            }
            value={y}
            className="m-t-50"
            onChange={(_, v) => setY(v)}
          />
        </div>
      </div>
      <div className="edit-panel">
        <div className="items">
          <div className="sub-title">Anchor</div>
          <Select
            width="150px"
            value={anchor}
            onChange={(e) => setAnchor(e.target.value)}
          >
            <option value="top-left">top-left</option>
            <option value="top-right">top-right</option>
            <option value="top-center">top-center</option>
            <option value="bottom-left">bottom-left</option>
            <option value="bottom-right">bottom-right</option>
            <option value="bottom-center">bottom-center</option>
            <option value="center=center">center=center</option>
          </Select>
        </div>
        <div className="items">
          <div className="sub-title">Z-Index</div>
          <InputField
            width="150px"
            type="number"
            label="z-index"
            value={z.toString()}
            onChange={(e) => setZ(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
