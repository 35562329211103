import { useState, useEffect } from "react";
import { Stack, Dialog, DialogTitle, DialogContent } from "@mui/material";

import { useDispatch } from "src/redux/store";
import { renameVideoFile } from "src/redux/slices/mainPage";
import MuiInput from "src/components/InputText/MuiInput";
import MuiButton from "src/components/Button/MuiButton";

export default function RenameVideoFileModal({ open, onClose, sourcePath }) {
  const [newName, setNewName] = useState("");
  const [path, setPath] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const slashIndex = sourcePath.lastIndexOf("/");
    if (slashIndex === -1) {
      setNewName(sourcePath);
    } else {
      setNewName(sourcePath.slice(slashIndex + 1));
      setPath(sourcePath.slice(0, slashIndex));
    }
  }, [sourcePath]);

  const handleAccept = async () => {
    const destPath = `${path}/${newName}`;
    if (sourcePath !== destPath) {
      await dispatch(renameVideoFile({ sourcePath, destPath }));
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Rename File</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <MuiInput
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <Stack direction="row" justifyContent="flex-end">
            <MuiButton onClick={handleAccept}>Rename</MuiButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
