import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MuiButton from "src/components/Button/MuiButton";
import useAlertModal from "src/hooks/useAlertModal";

export default function AlertModal() {
  const { content, handleAction } = useAlertModal();

  return (
    <Dialog maxWidth="sm" fullWidth open={!!content.name}>
      <DialogTitle sx={{ "&.MuiDialogTitle-root": { padding: "5px 10px" } }}>
        ACAS
      </DialogTitle>
      <DialogContent dividers>{content.text}</DialogContent>
      <DialogActions>
        <MuiButton
          autoFocus
          onClick={() => {
            handleAction(false);
          }}
        >
          Cancel
        </MuiButton>
        <MuiButton onClick={() => handleAction(true)}>OK</MuiButton>
      </DialogActions>
    </Dialog>
  );
}
