import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import MuiButton from "src/components/Button/MuiButton";
import VideoListItem from "./VideoListItem";
import MuiInput from "src/components/InputText/MuiInput";
import { useDispatch } from "src/redux/store";
import { createNewDirectory } from "src/redux/slices/mainPage";

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(5, 1),
  //   borderRadius: theme.shape.borderRadius,
  borderRadius: 8,
  //   backgroundColor: theme.palette.background.neutral,
  backgroundColor: "#F4F6F8",
  //   border: `1px dashed ${theme.palette.grey[500_32]}`,
  border: "1px dashed rgba(145, 158, 171, 0.32)",
  "&:hover": { opacity: 0.72, cursor: "pointer" },
  //   [theme.breakpoints.up("md")]: { textAlign: "left", flexDirection: "row" },
}));

const mp4Validator = (file) => {
  if (!file.name.endsWith("mp4")) {
    return {
      code: "no-mp4-file",
      message: "MP4 file is only supported",
    };
  }
  return null;
};

export default function UploadModal({ open, onClose, sourcePath }) {
  const [files, setFiles] = useState([]);
  const [destPath, setDestPath] = useState("");
  const [message, setMessage] = useState("");
  const [newDirectory, setNewDirectory] = useState("");

  useEffect(() => {
    setDestPath(sourcePath === ".." ? "" : sourcePath);
  }, [sourcePath]);

  const dispatch = useDispatch();

  //   const handleDrop = useCallback(
  //     (acceptedFiles) => {
  //       setFiles(
  //         acceptedFiles.map((file) =>
  //           Object.assign(file, {
  //             preview: URL.createObjectURL(file)
  //           })
  //         )
  //   )},
  //     [setFieldValue]
  //   );

  const handleDrop = (acceptedFiles) => {
    // setFiles(
    //   files.concat(
    //     acceptedFiles.map((file) =>
    //       Object.assign(file, {
    //         preview: URL.createObjectURL(file),
    //       })
    //     )
    //   )
    // );
    setFiles(files.concat(acceptedFiles));
  };

  const handleCreateDirectory = async () => {
    if (newDirectory !== "") {
      const response = await dispatch(
        createNewDirectory({ destPath: `${destPath}/${newDirectory}` })
      );
      if (
        response.status === "success" ||
        response.msg === "Directory already exists."
      ) {
        setDestPath(`${destPath}/${newDirectory}`);
        setNewDirectory("");
      }
      setMessage(response.msg);
    }
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      // maxSize={}
      accept:
        // "video/x-msvideo,video/mp4,video/mpeg,video/ogg,video/mp2t,video/webm,video/x-m4v,video/*,.mkv",
        "video/mp4,video/*",
      // accept: {
      //   "video/x-msvideo": [".avi"],
      //   "video/mp4": [".mp4"],
      //   "video/mpeg": [".mpeg"],
      //   "video/mp2t": [".ts"],
      // },
      onDrop: handleDrop,
      validator: mp4Validator,
    });

  const handleClose = () => {
    setMessage("");
    setNewDirectory("");
    setFiles([]);
    onClose();
  };

  const handleCancelFile = (idx) => {
    setFiles(files.filter((_, index) => index !== idx));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Upload Video Files</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <MuiInput value={destPath} readOnly />
          <Stack direction="row" spacing={2}>
            <MuiInput
              value={newDirectory}
              sx={{ flexGrow: 1 }}
              onChange={(e) => {
                setNewDirectory(e.target.value);
                setMessage("");
              }}
            />
            <MuiButton onClick={handleCreateDirectory}>New Directory</MuiButton>
          </Stack>
          <Typography variant="caption">{message}</Typography>
          <Stack direction="row" spacing={3}>
            <Box>
              <DropZoneStyle
                {...getRootProps()}
                sx={{
                  ...(isDragActive && { opacity: 0.72 }),
                }}
              >
                <input {...getInputProps()} />

                <Stack spacing={2} alignItems="center">
                  <CloudUploadIcon
                    sx={{ width: 60, height: 60, color: "#8EC044" }}
                  />
                  <Box sx={{ p: 3, ml: { md: 2 } }}>
                    <Typography gutterBottom variant="h5" textAlign="center">
                      Drop or Select file
                    </Typography>
                  </Box>
                </Stack>
              </DropZoneStyle>
              {fileRejections.length > 0 && (
                <Typography
                  variant="subtitle2"
                  textAlign="center"
                  color="error"
                  mt={1}
                >
                  {fileRejections[0].errors[0].message}
                </Typography>
              )}
            </Box>

            <List disablePadding sx={{ my: 3, flexGrow: 1 }}>
              {files.map((file, idx) => {
                return (
                  <VideoListItem
                    key={idx}
                    targetFile={file}
                    destPath={destPath}
                    handleCancelFile={() => handleCancelFile(idx)}
                  />
                );
              })}
            </List>
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <MuiButton onClick={handleClose}>Close</MuiButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
