import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function MainLoadingScreen({ sx = {} }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%", minHeight: "100%", ...sx }}
    >
      <CircularProgress />
    </Box>
  );
}
