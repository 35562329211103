import { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TheatersIcon from "@mui/icons-material/Theaters";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import SearchIcon from "@mui/icons-material/Search";

import ProgramsComponent from "./ProgramTab";
import PadTab from "./PadTab";
import LiveTab from "./LiveTab";
import ProgramTabPanel from "../MiddlePanel/ProgramTabPanel";
import PadTabPanel from "../MiddlePanel/PadTabPanel";
import LiveTabPanel from "../MiddlePanel/LiveTabPanel";
import RightPanel from "../RightPanel";

import { useDispatch, useSelector } from "src/redux/store";
import {
  getLiveContent,
  getPadTableData,
  getProgramTableData,
  setChannel,
  setPadKey,
} from "src/redux/slices/mainPage";
import useAuth from "src/hooks/useAuth";
import { makeTimeDuration } from "src/utils";
import { InputField } from "src/components";
import MuiSelect from "src/components/Select/MuiSelect";
import MuiButton from "src/components/Button/MuiButton";
import WebRTCPlayer from "src/components/WebRTC";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function MainPage({ dockPanel }) {
  const [tabName, setTabName] = useState(0);
  const [search, setSearch] = useState("");

  const { logout, user } = useAuth();
  const dispatch = useDispatch();
  const {
    channel,
    channels,
    selectedDate,
    defaultChannelData,
    padTableList,
    showTableKey,
  } = useSelector((state) => state.mainPage);

  useEffect(() => {
    const getData = async () => {
      switch (tabName) {
        case 1:
          await dispatch(getPadTableData());
          break;
        case 2:
          await dispatch(getLiveContent());
          break;
        default:
          await dispatch(getProgramTableData(channel, selectedDate));
          break;
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName, channel]);

  useEffect(() => {
    if (Object.keys(padTableList).length > 0) {
      if (showTableKey.length === 0) {
        dispatch(setPadKey([Object.keys(padTableList).sort()[0]]));
      } else {
        dispatch(setPadKey(showTableKey));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [padTableList]);

  return (
    <Fragment>
      <Box className={`left-box${dockPanel === "left" ? " active" : ""}`}>
        <Stack spacing={2} className="left-panel">
          <Box
            sx={{
              height: 168,
              borderRadius: "5px",
              backgroundColor: "rgb(142 192 68)",
            }}
          >
            <WebRTCPlayer
              size={{ height: "auto", width: "100%" }}
              signalingURL={user.SignalingURL}
              applicationName={user.appName}
              streamName={`${channel}${user.overlay ? "_output" : ""}`}
              play={true}
            />
          </Box>
          <Stack spacing={2} className="select-panel">
            <Tabs
              value={tabName}
              onChange={(e, newValue) => {
                setSearch("");
                setTabName(newValue);
              }}
              sx={{
                "&.MuiTabs-root": {
                  minHeight: "100%",
                },
                "& .Mui-selected": {
                  color: "#FFFFFF !important",
                  backgroundColor: "#8EC044",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .MuiTabs-flexContainer": {
                  justifyContent: "space-between",
                },
                "& .MuiButtonBase-root": {
                  flex: "1 1 0",
                  fontSize: ".7rem",
                  fontWeight: 800,
                  color: "#495057",
                  borderRadius: "5px",
                  border: "1px solid #8EC044",
                  minHeight: "100%",
                  padding: "5px 10px",
                },
              }}
            >
              <Tab
                label="Program"
                icon={<CalendarMonthIcon />}
                iconPosition="start"
              />
              <Tab label="Pad" icon={<TheatersIcon />} iconPosition="start" />
              <Tab label="Live" icon={<LiveTvIcon />} iconPosition="start" />
            </Tabs>
            <TabPanel value={tabName} index={0}>
              <ProgramsComponent />
            </TabPanel>
            <TabPanel value={tabName} index={1}>
              <PadTab />
            </TabPanel>
            <TabPanel value={tabName} index={2}>
              <LiveTab />
            </TabPanel>
          </Stack>
        </Stack>
      </Box>
      <Box className="center-box">
        <Stack spacing={3} alignContent="stretch">
          <Stack direction="row" spacing={3} alignItems="center">
            <MuiSelect
              // Todo change items to all Channels
              items={defaultChannelData}
              value={channel}
              onSelect={(evt) => dispatch(setChannel(evt.target.value))}
              sx={{ flexGrow: 1 }}
            />
            <MuiButton onClick={() => logout()}>LogOut</MuiButton>
          </Stack>
          <Box className="search">
            <InputField
              value={search}
              appened={<SearchIcon />}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Stack direction="row" spacing={3} className="card-items">
            <Box className="card">
              {makeTimeDuration(
                channels.hasOwnProperty("schedulePlaylist")
                  ? channels.schedulePlaylist.startTime
                  : defaultChannelData[0].defaultPlaylist.startTime
              )}
            </Box>
            <Box className="card">
              {makeTimeDuration(
                channels.hasOwnProperty("schedulePlaylist")
                  ? channels.schedulePlaylist.stopTime
                  : defaultChannelData[0].defaultPlaylist.stopTime
              )}
            </Box>
            <Box className="card subject">
              <Box>
                {channels.hasOwnProperty("schedulePlaylist")
                  ? channels.schedulePlaylist.title
                  : defaultChannelData[0].defaultPlaylist.title}
              </Box>
              <Box>
                {channels.hasOwnProperty("scheduleItem")
                  ? `${channels.scheduleItem.title} - ${makeTimeDuration(
                      channels.scheduleItem.duration,
                      2
                    )}
                      `
                  : "Playlist - 00:00"}
              </Box>
            </Box>
          </Stack>
          <Box className="datatable" flexGrow={1}>
            <TabPanel value={tabName} index={0}>
              <ProgramTabPanel search={search} />
            </TabPanel>
            <TabPanel value={tabName} index={1}>
              <PadTabPanel search={search} />
            </TabPanel>
            <TabPanel value={tabName} index={2}>
              <LiveTabPanel />
            </TabPanel>
          </Box>
        </Stack>
      </Box>
      <Box className={`right-box${dockPanel === "right" ? " active" : ""}`}>
        <RightPanel tabIndex={tabName} />
      </Box>
    </Fragment>
  );
}
