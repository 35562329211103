import { useState, useEffect } from "react";
import {
  Box,
  Stack,
  List,
  Chip,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

import axios from "src/utils/axios";
import CONFIG from "src/config";
import MuiButton from "src/components/Button/MuiButton";
import MainLoadingScreen from "src/components/Loading/MainLoadingScreen";

export default function ViewUploadModal({ open, onClose }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setFiles([]);
    onClose();
  };

  useEffect(() => {
    axios.get(`${CONFIG.apiUrl}/viewupload`).then((res) => {
      if (Array.isArray(res.data)) {
        setLoading(false);
        setFiles(res.data);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle> Video files recently uploaded</DialogTitle>
      <DialogContent>
        {loading && <MainLoadingScreen />}
        {!loading && files.length === 0 ? (
          <Typography variant="subtitle2">There is no files</Typography>
        ) : (
          <Stack spacing={2}>
            <List disablePadding sx={{ my: 3, flexGrow: 1 }}>
              {files.map((file, idx) => (
                <ListItem
                  key={idx}
                  sx={{
                    my: 1,
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: "background.paper",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    flexGrow={1}
                    alignItems="center"
                  >
                    <OndemandVideoIcon sx={{ width: 40, height: 40 }} />

                    <Typography variant="subtitle2">{`${file.path}/${file.name}`}</Typography>
                    <Box flexGrow={1} />
                    <Chip
                      label={file.status}
                      color={
                        file.status === "completed" ? "success" : "warning"
                      }
                    />
                  </Stack>
                </ListItem>
              ))}
            </List>
            <Stack direction="row" justifyContent="flex-end">
              <MuiButton onClick={handleClose}>Close</MuiButton>
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
