import "./style.scss";

import React, { Component } from "react";
import PropsTypes from "prop-types";

import themeColor from "src/constants/themeColor";
export default class DataTable extends Component {
  static propsTypes = {
    headerColor: PropsTypes.string,
    class: PropsTypes.string,
    selected: PropsTypes.number,
    header: PropsTypes.array.isRequired,
    lists: PropsTypes.array.isRequired,
    config: PropsTypes.object,
  };

  makeStyle = () => {
    const { config } = this.props;
    return {
      "--panda-data-table-header-color":
        config.headerColor || themeColor["--primary-color"],
      "--panda-data-table-header-text-color":
        config.headerTextColor || themeColor["--default-font-color"],
    };
  };

  renderHeader = () => {
    const { header } = this.props;
    return (
      <tr>
        {header.map((element, key) => (
          <th
            key={key}
            className={element.class}
            style={{
              textAlign: element.align || "center",
              width: element.width || "auto",
              verticalAlign: "center",
            }}
          >
            {element.name}
          </th>
        ))}
      </tr>
    );
  };

  render() {
    const { children } = this.props;

    return (
      <div className="panda-data-table" style={this.makeStyle()}>
        <table>
          <thead>{this.renderHeader()}</thead>
          {children}
        </table>
      </div>
    );
  }
}
