import React, { Component } from 'react'

export default class AddTime extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill={this.props.fill || 'var(--primary-color)'} className={this.props.class} width="100%" height="100%" viewBox="0 0 24 24">
        <path d="M5 17v2h14v-2H5zm4.5-4.2h5l.9 2.2h2.1L12.75 4h-1.5L6.5 15h2.1l.9-2.2zM12 5.98L13.87 11h-3.74L12 5.98z"></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    )
  }
}
