import "./style.scss";

import { useState, useEffect } from "react";

import Slider from "@mui/material/Slider";
import { Select, CheckBox, ColorPicker } from "src/components";
import useAuth from "src/hooks/useAuth";
import { useDispatch, useSelector } from "src/redux/store";
import { setOverlay } from "src/redux/slices/overlay";

export default function FontComponent() {
  const { overlay } = useSelector((state) => state.overlay);

  const [font, setFont] = useState(
    overlay.style ? overlay.style.font : "SansSerif"
  );
  const [color, setColor] = useState(
    overlay.style ? overlay.style.color : "#000000"
  );
  const [style, setStyle] = useState(
    overlay.style ? overlay.style.style.split(" ") : []
  );
  const [size, setSize] = useState(overlay.style ? overlay.style.size : 20);

  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOverlay({ style: { ...overlay.style, font } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [font]);

  useEffect(() => {
    dispatch(setOverlay({ style: { ...overlay.style, size } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  useEffect(() => {
    dispatch(setOverlay({ style: { ...overlay.style, color } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  useEffect(() => {
    dispatch(
      setOverlay({ style: { ...overlay.style, style: style.join(" ") } })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style]);

  const changeStyle = (type) => {
    if (style.includes(type)) {
      setStyle(style.filter((i) => i !== type));
    } else {
      style.push(type);
      setStyle([...style, type]);
    }
  };
  const makeSampleStyle = (font, size, color, style) => {
    // const { font, size, color, style } = this.state;
    return {
      color,
      fontSize: `${size}px`,
      fontFamily: font,
      fontWeight: style.includes("bold") ? 800 : 100,
      fontStyle: style.includes("italic") ? "italic" : "normal",
    };
  };
  return (
    <div className="font-component">
      <div className="edit-panel">
        <div className="items">
          <div className="sub-title">Font Type</div>
          <Select
            width="150px"
            label="Family"
            value={font}
            onChange={(e) => setFont(e.target.value)}
          >
            {user.fonts ? (
              user.fonts
                .replace(/ /g, "")
                .split(",")
                .map((font) => {
                  return (
                    <option key={font} value={font}>
                      {font}
                    </option>
                  );
                })
            ) : (
              <>
                <option value="Sans">SansSerif</option>
                <option value="Sans">SansSerif</option>
                <option value="Sans">SansSerif</option>
                <option value="Sans">SansSerif</option>
                <option value="Sans">SansSerif</option>
              </>
            )}
          </Select>
        </div>
        <div className="items">
          <div className="sub-title">Font Style</div>
          <CheckBox
            checked={style.includes("bold")}
            onChange={() => changeStyle("bold")}
          >
            B
          </CheckBox>
          <CheckBox
            checked={style.includes("italic")}
            onChange={() => changeStyle("italic")}
          >
            I
          </CheckBox>
        </div>
      </div>
      <div className="edit-panel">
        <div className="items">
          <div className="sub-title">Font Size</div>
          <Slider
            min={10}
            max={100}
            value={size}
            onChange={(e) => setSize(e.target.value)}
            valueLabelDisplay="on"
            className="m-t-50"
          />
        </div>
        <div className="items">
          <div className="sub-title">Color</div>
          <ColorPicker onChange={(v) => setColor(v)} color={color} />
        </div>
      </div>
      <div className="edit-panel">
        <div className="items">
          <div
            className="sub-title"
            style={makeSampleStyle(font, size, color, style)}
          >
            Sample
          </div>
        </div>
      </div>
    </div>
  );
}
