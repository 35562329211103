import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MuiButton from "src/components/Button/MuiButton";
import MuiInput from "src/components/InputText/MuiInput";

export default function CalendarModal({
  open,
  onClose,
  showTime,
  selectedDate,
  handleCopyAction,
  handleDateChange,
  defaultStartTime,
}) {
  const [value, setValue] = useState(
    showTime ? defaultStartTime : selectedDate
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="calendar-dialog-title"
    >
      <DialogTitle id="calendar-dialog-title">Select Date</DialogTitle>
      <DialogContent>
        {!showTime ? (
          <DatePicker
            inputFormat="MM/dd/yyyy"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              handleDateChange(newValue);
            }}
            minDate={new Date()}
            renderInput={(params) => (
              <MuiInput
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root.MuiInputBase-root": {
                    borderRadius: "4px !important",
                    border: "1px solid #8EC044 !important",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                    {
                      color: "black !important",
                      width: "100%",
                      backgroundColor: "white !important",
                    },
                  width: "100%",
                }}
              />
            )}
          />
        ) : (
          <DateTimePicker
            ampm={false}
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              handleDateChange(newValue);
            }}
            minDate={new Date()}
            inputFormat="MM/dd/yyyy HH:mm:ss"
            mask="__/__/____ __:__:__"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            renderInput={(params) => (
              <MuiInput
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root.MuiInputBase-root": {
                    borderRadius: "4px !important",
                    border: "1px solid #8EC044 !important",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-input.MuiInputBase-input":
                    {
                      color: "black !important",
                      width: "100%",
                      backgroundColor: "white !important",
                    },
                  width: "100%",
                }}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={onClose}>Cancel</MuiButton>
        <MuiButton onClick={handleCopyAction}>Ok</MuiButton>
      </DialogActions>
    </Dialog>
  );
}
