import IconButton from "@mui/material/IconButton";
import { themeColorArray } from "../consts";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";

export default function MuiSvgIconButton({
  icon,
  textColor,
  disabled,
  ...others
}) {
  let svgColor = disabled
    ? "gray"
    : themeColorArray[textColor] || textColor || themeColorArray["primary"];
  return (
    <IconButton {...others} disabled={disabled}>
      {icon === "add-playlist" && <PlaylistAddIcon sx={{ color: svgColor }} />}
      {icon === "add-time" && <MoreTimeIcon sx={{ color: svgColor }} />}
      {icon === "remove" && <HighlightOffIcon sx={{ color: svgColor }} />}
      {icon === "copy" && <FileCopyIcon sx={{ color: svgColor }} />}
      {icon === "upload" && <CloudUploadIcon sx={{ color: svgColor }} />}
    </IconButton>
  );
}
