const THEME_COLORS = {
  primary: {
    light: "#9dd44c",
    main: "#8EC044",
    dark: "#79a539",
    contrastText: "#fff",
  },
  secondary: {
    light: "#757ce8",
    main: "#3f50b5",
    dark: "#002884",
    contrastText: "#fff",
  },
};

export default THEME_COLORS;
