import { Fragment, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import TvIcon from "@mui/icons-material/Tv";

import useMainModal from "src/hooks/useMainModal";
import { useDispatch, useSelector } from "src/redux/store";
import MainLoadingScreen from "src/components/Loading/MainLoadingScreen";
import LiveVideoPreview from "../MainPage/VideoPreview/LiveVideoPreview";
import { probeVideo } from "src/redux/slices/mainPage";
import useAuth from "src/hooks/useAuth";

export default function LiveTabPanel() {
  const [firstStreamData, setFirstStreamData] = useState({});
  const [secondStreamData, setSecondStreamData] = useState({});
  const [formatData, setFormatData] = useState({});

  const { liveContent, selectedLive, defaultChannelData, isLoading } =
    useSelector((state) => state.mainPage);

  const showMainModal = useMainModal();
  const dispatch = useDispatch();
  const { user } = useAuth();

  useEffect(() => {
    if (selectedLive !== "") {
      const getData = async () => {
        const response = await dispatch(
          probeVideo({
            src: `${user.livepreview}/${defaultChannelData[0].appName}/${selectedLive}`
              .replace("https", "rtmp")
              .replace("443", "1935"),
          })
        );
        setFirstStreamData(response.streams[1]);
        setSecondStreamData(response.streams[2]);
        setFormatData(response.format);
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLive]);

  return (
    <div className="live-component">
      {!isLoading ? (
        <Fragment>
          <div className="preview">
            {selectedLive !== "" ? (
              <LiveVideoPreview
                id="live-video-preview"
                // size={{ width: "100%", height: "auto" }}
              />
            ) : (
              <div className="no-preview">There is no live contents.</div>
            )}
          </div>
          <div className="detail">
            <div className="live-stream">
              <span>
                <img src={require("src/assets/image/asd.png")} alt="" />
              </span>
              Live Stream
            </div>
            <div className="content">
              <ul className="live_ul">
                <li>
                  Status: {firstStreamData.codec_name && <span>Online</span>}
                </li>
                <li>
                  Video Codec: <span>{firstStreamData.codec_name || ""}</span>
                </li>
                <li>
                  Video Bitrate(kbps):{" "}
                  {formatData.bit_rate && (
                    <span>{Math.round(formatData.bit_rate / 1000)}</span>
                  )}
                </li>
                <li>
                  V.Resolution:{" "}
                  {firstStreamData.coded_width && (
                    <span>{`${firstStreamData.coded_width}x${firstStreamData.coded_height} ${firstStreamData.display_aspect_ratio}`}</span>
                  )}
                </li>
              </ul>
            </div>
            <div>
              <ul className="live_ul">
                <li>
                  Video Profil:{" "}
                  {firstStreamData.profile && (
                    <span>{firstStreamData.profile}</span>
                  )}
                </li>
                <li>
                  Audio:{" "}
                  {secondStreamData.codec_name && (
                    <span>{`${secondStreamData.codec_name.toUpperCase()}.${
                      secondStreamData.profile
                    } ${secondStreamData.sample_rate}`}</span>
                  )}
                </li>
                <li>
                  Audio Channel:{" "}
                  {secondStreamData.channel_layout && (
                    <span>
                      {secondStreamData.channel_layout.charAt(0).toUpperCase() +
                        secondStreamData.channel_layout.slice(1)}
                    </span>
                  )}
                </li>
              </ul>
            </div>
            <div className="actions">
              <Button
                color="error"
                variant="contained"
                disabled={liveContent.length === 0}
                startIcon={<TvIcon />}
                onClick={() =>
                  showMainModal({ modalName: "go-live-modal", status: true })
                }
              >
                Go Live
              </Button>
            </div>
          </div>
        </Fragment>
      ) : (
        <MainLoadingScreen />
      )}
    </div>
  );
}
