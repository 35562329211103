import "./style.scss";

import { useState } from "react";
import { FaIconButton } from "src/components";
export default function ExpandRow({
  row,
  detail,
  list,
  type,
  selected,
  vod,
  arrowAble,
  className,
  onClick,
  expandIconcolor,
}) {
  const [open, setOpen] = useState(false);

  let bColor;
  if (type) {
    if (type === "Default") {
      bColor = "#FFC107";
    } else {
      const redList = list.items.filter((item) => item.vod === false);
      if (redList.length > 0) bColor = "red";
    }
  }

  return (
    <tbody>
      <tr
        className={className}
        selected={selected}
        onClick={onClick}
        style={{
          backgroundColor: bColor,
        }}
      >
        {list.items && list.items.length > 0 ? (
          <td onClick={() => setOpen(!open)}>
            <FaIconButton
              icon={`fa-angle-${open ? "down" : "right"}`}
              color={expandIconcolor}
              badge={list.items.length}
              selected={selected}
              vod={vod}
              arrowAble={arrowAble}
            />
          </td>
        ) : (
          <td></td>
        )}
        {row}
      </tr>
      {open && (
        <tr>
          <td></td>
          <td colSpan={6}>{detail}</td>
        </tr>
      )}
    </tbody>
  );
}
