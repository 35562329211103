import { NotificationManager } from "react-notifications";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { VideoPreview, Button } from "src/components";
import useAuth from "src/hooks/useAuth";
import useMainModal from "src/hooks/useMainModal";
import Facebook from "./Facebook";
import Youtube from "./Youtube";
import { useSelector } from "src/redux/store";
import PadSelectedList from "./PadSelectedList";

export default function RightPanel({ tabIndex }) {
  const { selectedVideo } = useSelector((state) => state.mainPage);
  const { user } = useAuth();
  const showMainModal = useMainModal();

  const openOverlay = (key) => {
    const warningExist = warningMsg();
    if (warningExist) {
      return false;
    }
    showMainModal({ modalName: key, status: true });
  };
  const warningMsg = () => {
    if (!user.overlay) {
      NotificationManager.warning(
        "You don't have Overlay Option. For more details, please contact our Customer Service at support@acan.email.",
        "Warning",
        3000
      );
      return true;
    }
    return false;
  };

  return (
    <div className="right-panel">
      <Stack spacing={2}>
        <VideoPreview id="top-right-preview-player" />
        {selectedVideo !== "" && (
          <Typography
            variant="caption"
            textAlign="center"
            sx={{
              wordBreak: "break-word",
            }}
          >
            {selectedVideo}
          </Typography>
        )}
        {tabIndex !== 1 ? (
          <Stack spacing={2}>
            <Button
              textColor="white"
              onClick={() => openOverlay("overlay-modal")}
            >
              <i className="fa fa-camera" />
              Overlay Lists
            </Button>
            <Button
              textColor="white"
              onClick={() => openOverlay("add-new-overlay-drawer")}
            >
              <i className="fa fa-plus-circle" />
              Add an Overlay
            </Button>
            {/* <div className="subject">Live Social Network</div> */}
            <Facebook />
            <Youtube />
            {/* <div className="subject">PUBLISH/SHARE</div> */}
            <Button>EMBED CODE</Button>
          </Stack>
        ) : (
          <PadSelectedList />
        )}
      </Stack>
    </div>
  );
}
