import "./style.scss";

import { useEffect, useCallback, useRef } from "react";
import YouTube from "@mui/icons-material/YouTube";
import { InputField } from "src/components";
import PlayButton from "../PlayButton";
import { setYoutubeLiveStatus } from "src/redux/slices/youtube";
import { useDispatch, useSelector } from "src/redux/store";

export default function Youtube() {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { youtube, mainPage } = useSelector((state) => state);

  useEffect(() => {
    const youtubeInterval = setInterval(() => {
      dispatch(setYoutubeLiveStatus(mainPage.channel, "", "status"));
    }, 120000);
    return () => {
      clearInterval(youtubeInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPage.channel]);

  const handleClick = useCallback(
    async () => {
      await dispatch(
        setYoutubeLiveStatus(
          mainPage.channel,
          inputRef.current.value,
          !youtube.status ? "start" : "stop"
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mainPage.channel, youtube.status]
  );

  return (
    <div className="youtube-live">
      <div className="title">
        <div className="icon">
          <YouTube />
        </div>
        YouTube Live
      </div>
      <div className="content">
        <InputField label="Streamkey" textColor="white" ref={inputRef} />
      </div>
      <div className="actions">
        <PlayButton onClick={handleClick} playing={youtube.status} />
      </div>
    </div>
  );
}
