import { combineReducers } from "redux";
import mainPageReducer from "./slices/mainPage";
// import alertReducer from "./slices/alert";
import facebookReducer from "./slices/facebook";
import youtubeReducer from "./slices/youtube";
import overlayReducer from "./slices/overlay";

const rootReducer = combineReducers({
  mainPage: mainPageReducer,
  // alert: alertReducer,
  facebook: facebookReducer,
  youtube: youtubeReducer,
  overlay: overlayReducer,
});

export default rootReducer;
