const CONFIG = {
  apiUrl: "https://acasapi.acan.group/api",
  // apiUrl: "http://localhost:8000/api",
  clientId: "9637f506-4e82-49c7-8969-98dbc3b8ba40",
  secret: "IMHtIEZTtWFstAKhbyGbSvgsEbQTky90f90NGYBr",
  userLocalstorage: "acas-user-info",
  apiTimeout: { timeout: 35000 },
  videoServer: "https://edge1.acangroup.org:1922/vod/_definst_/mp4:",
  appName: "asfiyahitv",
  serverUrl: "https://acasapi.acan.group/api/acanstreamer",
  // serverUrl: "http://localhost:8000/api/acanstreamer",
  overlayUrl: "https://acasapi.acan.group/api/acanoverlay",
  // overlayUrl: "http://localhost:8000/api/acanoverlay",
};

export default CONFIG;
