import { Fragment } from "react";

import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reOrder } from "src/utils/reOrder";
import { useDispatch, useSelector } from "src/redux/store";
import { setSelectedFileList } from "src/redux/slices/mainPage";
import PlayListActionGroup from "src/pages/main/PlayListActionGroup";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "5px",
  margin: `0 0 5px 0`,
  background: isDragging ? "grey" : "#8EC044",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
  padding: 8,
  width: "100%",
});

export default function PadSelectedList() {
  const { selectedFileList } = useSelector((state) => state.mainPage);
  const dispatch = useDispatch();

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = reOrder(
      selectedFileList,
      result.source.index,
      result.destination.index
    );
    dispatch(setSelectedFileList(items));
  };

  const removeFileFromList = (item) => {
    dispatch(
      setSelectedFileList(selectedFileList.filter((i) => i.name !== item.name))
    );
  };

  return (
    <Fragment>
      <Stack justifyContent="end">
        <PlayListActionGroup
          selectedFileList={selectedFileList}
          disabled={selectedFileList.length === 0}
        />
      </Stack>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="video-files-droppable-zone">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              {...provided.droppableProps}
              // style={{ backgroundColor: "grey" }}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {selectedFileList.map((item, idx) => (
                <Draggable
                  draggableId={`${idx}_${item.name}`}
                  index={idx}
                  key={idx}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      secondaryAction={
                        <IconButton onClick={() => removeFileFromList(item)}>
                          <CloseIcon sx={{ color: "white", fontSize: 16 }} />
                        </IconButton>
                      }
                      sx={{ px: 1 }}
                    >
                      <ListItemText
                        title={item.name.slice(0, item.name.lastIndexOf("/"))}
                        primary={`${item.name
                          .slice(item.name.lastIndexOf("/") + 1)
                          .slice(0, 20)}...`}
                        sx={{ wordBreak: "break-word", maxWidth: 250 }}
                      />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Fragment>
  );
}
