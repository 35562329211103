import { useState, useMemo } from "react";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Modal from "src/components/Modal";
import MuiButton from "src/components/Button/MuiButton";
import MuiRadio from "src/components/Radio";
import {
  addPlayList,
  endLoading,
  removePlayList,
  setSelectedFileList,
} from "src/redux/slices/mainPage";
import { useDispatch, useSelector } from "src/redux/store";

export default function AddItemsModal({ open, onClose, playlistId, items }) {
  const [includeOrigin, setIncludeOrigin] = useState("no");
  const [position, setPosition] = useState("before");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { channel, programTableList } = useSelector((state) => state.mainPage);

  const pl = useMemo(() => {
    return programTableList.find((i) => i.id === playlistId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId]);

  const handleOk = async () => {
    setLoading(true);

    if (includeOrigin === "yes") {
      let itemsToAdd = [];
      if (position === "before") {
        itemsToAdd = items.concat(pl.items);
      } else {
        itemsToAdd = pl.items.concat(items);
      }
      if (itemsToAdd.length > 0) {
        await dispatch(
          addPlayList({
            channel,
            items: itemsToAdd,
            title: pl.title,
            shuffle: pl.shuffle,
            priority: pl.priority,
            time: pl.startTime,
          })
        );
        await dispatch(removePlayList(pl));
        await dispatch(endLoading());
        await dispatch(setSelectedFileList([]));
        handleClose();
      }
    } else {
      await dispatch(
        addPlayList({
          channel,
          items,
          title: pl.title,
          shuffle: pl.shuffle,
          priority: pl.priority,
          time: pl.startTime,
        })
      );
      await dispatch(removePlayList(pl));
      await dispatch(endLoading());
      await dispatch(setSelectedFileList([]));
      handleClose();
    }
  };

  const handleClose = () => {
    setLoading(false);
    setIncludeOrigin("no");
    setPosition("before");
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} loading={loading} width="500px">
      <div className="subject text-center">
        Add items to playlist {pl.title}
      </div>
      <Stack spacing={2} padding={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Do you want to include exsiting files?
          </FormLabel>
          <RadioGroup
            name="include"
            value={includeOrigin}
            onChange={(e) => setIncludeOrigin(e.target.value)}
          >
            <MuiRadio value="yes" label="Yes" />
            <MuiRadio value="no" label="No" />
          </RadioGroup>
        </FormControl>
        {includeOrigin === "yes" && (
          <FormControl component="fieldset">
            <FormLabel component="legend">Select the position</FormLabel>
            <RadioGroup
              name="before_or_after"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            >
              <MuiRadio value="before" label="Before existing files" />
              <MuiRadio value="after" label="After existing files" />
            </RadioGroup>
          </FormControl>
        )}
      </Stack>
      <div className="actions">
        <MuiButton onClick={() => handleOk()}>Ok</MuiButton>
      </div>
    </Modal>
  );
}
