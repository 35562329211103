import "./style.scss";

import { useEffect, useRef, useCallback } from "react";
import { InputField } from "src/components";
import PlayButton from "../PlayButton";
import { useDispatch, useSelector } from "src/redux/store";
import { setFacebookLiveStatus } from "src/redux/slices/facebook";

export default function Facebook() {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { facebook, mainPage } = useSelector((state) => state);

  useEffect(() => {
    const facebookInterval = setInterval(() => {
      dispatch(setFacebookLiveStatus(mainPage.channel, "", "status"));
    }, 120000);
    return () => {
      clearInterval(facebookInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPage.channel]);

  const onClick = useCallback(async () => {
    await dispatch(
      setFacebookLiveStatus(
        mainPage.channel,
        inputRef.current.value,
        !facebook.status ? "start" : "stop"
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainPage.channel, facebook.status]);

  return (
    <div className="face-book-live">
      <div className="title">
        <div className="icon">
          <i className="fa fa-facebook"></i>
        </div>
        Facebook Live
      </div>
      <div className="content">
        <InputField label="Streamkey" textColor="white" ref={inputRef} />
      </div>
      <div className="actions">
        <PlayButton onClick={onClick} playing={facebook.status} />
      </div>
    </div>
  );
}
