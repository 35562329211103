import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import themeColor from "src/constants/themeColor";
import { themeColorArray } from "../consts";

/**
 * Radio Component
 * @augments {Component<Props, State>}
 */
export default function MuiRadio({ color = "primary", value, label }) {
  const svgColor =
    themeColorArray[color] || color || themeColor["--primary-color"];

  return (
    <FormControlLabel
      sx={{ display: "flex" }}
      value={value}
      control={
        <Radio
          sx={{
            "& .MuiSvgIcon-root": {
              fill: svgColor,
            },
          }}
        />
      }
      label={label}
    />
  );
}
