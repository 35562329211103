import { useState } from "react";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";

import Modal from "src/components/Modal";
import MuiRadio from "src/components/Radio";
import { addLivePull, getLiveContent } from "src/redux/slices/mainPage";
import { useDispatch } from "src/redux/store";
import MuiInput from "src/components/InputText/MuiInput";
import { NotificationManager } from "react-notifications";

export default function AddLiveModal({ open, onClose }) {
  const [liveType, setLiveType] = useState("RTMP");
  const [name, setName] = useState("");
  const [liveURI, setLiveURI] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleAction = async () => {
    setLoading(true);
    const response = await dispatch(
      addLivePull({ type: liveType, name, uri: liveURI })
    );
    await dispatch(getLiveContent());
    if (response.success) {
      NotificationManager.success(response.message);
    } else {
      NotificationManager.warning(response.message);
    }
    setLoading(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} loading={loading} width="500px">
      <div className="subject text-center">Add a Live</div>
      <Stack spacing={2} padding={2}>
        <FormControl>
          <FormLabel id="live-type-radio">Type</FormLabel>
          <RadioGroup
            aria-labelledby="live-type-radio"
            name="type"
            value={liveType}
            onChange={(e) => setLiveType(e.target.value)}
          >
            <MuiRadio value="HLS" label="HLS" />
            <MuiRadio value="RTMP" label="RTMP" />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <MuiInput
            name="name"
            type="text"
            value={name}
            placeholder="Live Name"
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <MuiInput
            name="liveURI"
            type="text"
            value={liveURI}
            placeholder="Live URI"
            onChange={(e) => setLiveURI(e.target.value)}
          />
        </FormControl>
        <div className="actions">
          <Button
            variant="contained"
            color="error"
            onClick={() => handleAction()}
          >
            Add a Live
          </Button>
        </div>
      </Stack>
    </Modal>
  );
}
