import { createContext, useState } from "react";

import AddMediaModal from "src/pages/main/Modals/AddMediaModal";
import AddPlaylistModal from "src/pages/main/Modals/AddPlaylistModal";
import GoLiveModal from "src/pages/main/Modals/GoLiveModal";
import AddLiveModal from "src/pages/main/Modals/AddLiveModal";
import OverlayModal from "src/pages/main/Modals/OverlayModal";
import ChangeOverlayIdModal from "src/pages/main/Modals/OverlayModal/ChangeOverlayId";
import EditOverlay from "src/pages/main/Modals/OverlayModal/EditOverlay";
import AddNewItemsDrawer from "src/pages/main/Modals/OverlayModal/AddNewItemsDrawer";
import AddItemsModal from "src/pages/main/Modals/AddItemsModal";
// import CalendarModal from "src/pages/main/Modals/CalendarModal";

const MainModalContext = createContext();

function MainModalProvider({ children }) {
  const [data, setData] = useState([]);
  const [plId, setPlId] = useState(null);
  const [showAddPlaylistModal, setShowAddPlaylistModal] = useState(false);
  const [showAddMediaModal, setShowAddMediaModal] = useState(false);
  const [showOverlayModal, setShowOverlayModal] = useState(false);
  const [showChangeOverlayModal, setShowChangeOverlayModal] = useState(false);
  const [showAddItemsDrawer, setShowAddItemsDrawer] = useState(false);
  const [showAddLiveModal, setShowAddLiveModal] = useState(false);
  const [showGoLiveModal, setShowGoLiveModal] = useState(false);
  const [showEditOverlayModal, setShowEditOverlayModal] = useState(false);
  const [showAddItemsModal, setShowAddItemsModal] = useState(false);
  const [hasToDelete, setHasToDelete] = useState(false);
  const [overlayType, setOverlayType] = useState("");
  const [program, setProgram] = useState({});
  //   const [showCalendarModal, setShowCalendarModal] = useState(false);

  const handleModalStatus = ({
    modalName = "",
    status = false,
    items = [],
    playlistId = 0,
    deletePL = false,
    olType = "",
    program = {},
  }) => {
    switch (modalName) {
      case "add-playlist":
        setData(items);
        setShowAddPlaylistModal(status);
        break;
      case "add-time":
        setData(items);
        setPlId(playlistId);
        setShowAddMediaModal(status);
        if (deletePL) {
          setHasToDelete(deletePL);
        }
        break;
      case "overlay-modal":
        setShowOverlayModal(status);
        break;
      case "overlay-edit-modal":
        setShowEditOverlayModal(status);
        setOverlayType(olType);
        break;
      case "change-overlay-id-modal":
        setShowChangeOverlayModal(status);
        break;
      case "add-new-overlay-drawer":
        setProgram(program);
        setShowAddItemsDrawer(status);
        break;
      case "go-live-modal":
        setData(items);
        setPlId(playlistId);
        setShowGoLiveModal(status);
        break;
      case "add-live-modal":
        setShowAddLiveModal(status);
        break;
      case "add-items-to-playlist":
        setData(items);
        setPlId(playlistId);
        setShowAddItemsModal(status);
        break;
      default:
        break;
    }
  };
  return (
    <MainModalContext.Provider value={handleModalStatus}>
      {children}

      {showAddMediaModal && (
        <AddMediaModal
          open={showAddMediaModal}
          items={data}
          playlistId={plId}
          hasToDelete={hasToDelete}
          onClose={() => setShowAddMediaModal(false)}
        />
      )}

      {showAddPlaylistModal && (
        <AddPlaylistModal
          open={showAddPlaylistModal}
          items={data}
          onClose={() => setShowAddPlaylistModal(false)}
        />
      )}

      {showGoLiveModal && (
        <GoLiveModal
          open={showGoLiveModal}
          items={data}
          playlistId={plId}
          onClose={() => setShowGoLiveModal(false)}
        />
      )}

      {showAddLiveModal && (
        <AddLiveModal
          open={showAddLiveModal}
          onClose={() => setShowAddLiveModal(false)}
        />
      )}

      {showOverlayModal && (
        <OverlayModal
          open={showOverlayModal}
          onClose={() => setShowOverlayModal(false)}
        />
      )}

      {showEditOverlayModal && (
        <EditOverlay
          open={showEditOverlayModal}
          onClose={() => setShowEditOverlayModal(false)}
          overlayType={overlayType}
          program={program}
        />
      )}

      {showChangeOverlayModal && (
        <ChangeOverlayIdModal
          open={showChangeOverlayModal}
          onClose={() => setShowChangeOverlayModal(false)}
          openOverlayFunction={() =>
            handleModalStatus({ modalName: "overlay-modal", status: true })
          }
        />
      )}

      {showAddItemsDrawer && (
        <AddNewItemsDrawer
          open={showAddItemsDrawer}
          program={program}
          onClose={() => {
            setShowAddItemsDrawer(false);
          }}
        />
      )}

      {showAddItemsModal && (
        <AddItemsModal
          open={showAddItemsModal}
          onClose={() => setShowAddItemsModal(false)}
          items={data}
          playlistId={plId}
        />
      )}
    </MainModalContext.Provider>
  );
}

export { MainModalContext, MainModalProvider };
