import { createContext, useCallback, useRef } from "react";
import { useImmer } from "use-immer";

const AlertModalContext = createContext({
  content: { name: "", text: "" },
  handleAction: () => {},
  show: () => {},
});

function AlertModalContextProvider({ children }) {
  const callbackRef = useRef(null);
  const [content, setContent] = useImmer({ name: "", text: "" });

  const handleAction = useCallback(async (accepted) => {
    setContent({ text: "", name: "" });
    if (accepted) {
      const handleAccept = callbackRef.current;
      if (handleAccept) {
        await handleAccept();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShow = useCallback(({ name, text, onAccept }) => {
    setContent((draft) => {
      draft.name = name;
      draft.text = text;
    });
    callbackRef.current = onAccept;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertModalContext.Provider
      value={{
        content,
        show: handleShow,
        handleAction,
      }}
    >
      {children}
    </AlertModalContext.Provider>
  );
}

export { AlertModalContext, AlertModalContextProvider };
