import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TextFormatIcon from "@mui/icons-material/TextFormat";

import { ImageIcon } from "src/components";
import { getOverlaySetting } from "src/utils/PandaUtils";
import useMainModal from "src/hooks/useMainModal";
import { setOverlay } from "src/redux/slices/overlay";
import { useDispatch } from "src/redux/store";

export default function AddNewItemsDrawer({ open, onClose, program }) {
  const showMainModal = useMainModal();
  const dispatch = useDispatch();

  const openChangeIdModal = async (type) => {
    onClose();
    dispatch(setOverlay(getOverlaySetting(type)));
    showMainModal({ modalName: "overlay-edit-modal", status: true, program });
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <List sx={{ maxHeight: 300 }}>
        <ListItem disablePadding sx={{ maxHeight: 50 }}>
          <ListItemButton onClick={() => openChangeIdModal("add-text")}>
            <ListItemIcon>
              <TextFormatIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Add Text" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ maxHeight: 50 }}>
          <ListItemButton onClick={() => openChangeIdModal("news-bar")}>
            <ListItemIcon>
              <TextFormatIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Add News Bar" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ maxHeight: 50 }}>
          <ListItemButton onClick={() => openChangeIdModal("clock")}>
            <ListItemIcon>
              <AccessTimeIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Add Clock" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ maxHeight: 50 }}>
          <ListItemButton onClick={() => openChangeIdModal("add-image")}>
            <ListItemIcon sx={{ color: "black", height: 24 }}>
              <ImageIcon />
            </ListItemIcon>
            <ListItemText primary="Add Image" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ maxHeight: 50 }}>
          <ListItemButton onClick={openChangeIdModal}>
            <ListItemIcon>
              <TextFormatIcon sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Add Countdown" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
