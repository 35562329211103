import { useRoutes } from "react-router-dom";

import AuthGuard from "src/guards/AuthGuard";
import GuestGuard from "src/guards/GuestGuard";
import MainApp from "src/pages/main";
import Login from "src/pages/auth/Login";

export default function MainRoute() {
  const routes = useRoutes([
    {
      path: "login",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainApp />
        </AuthGuard>
      ),
    },
  ]);
  return routes;
}
