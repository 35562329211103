import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "5px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#8EC044",
  borderColor: "#8EC044",
  "&:hover": {
    backgroundColor: "#8EC044",
    borderColor: "#8EC044",
    boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.1)",
  },

  "&:active": {
    boxShadow: "none",
    backgroundColor: "#8EC044",
    borderColor: "#8EC044",
  },
  "&:focus": {
    // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function MuiButton({ children, variant, sx, ...others }) {
  return (
    <BootstrapButton
      {...others}
      variant="contained"
      disableRipple
      sx={{ bgcolor: "#8EC044", color: "white", ...sx }}
    >
      {children}
    </BootstrapButton>
  );
}
