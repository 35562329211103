import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #8EC044",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
    },
  },
}));

export default function MuiSelect({
  value,
  items,
  placeholder,
  onSelect,
  sx,
  children,
}) {
  return (
    <Select
      id="channel-select"
      value={value}
      onChange={onSelect}
      input={<BootstrapInput />}
      sx={sx}
    >
      {placeholder && (
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
      )}
      {items
        ? items.map((item, idx) => (
            <MenuItem key={idx} value={item.name}>
              {item.name}
            </MenuItem>
          ))
        : children}
    </Select>
  );
}
