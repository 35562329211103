// import "./style.scss";

import { useEffect, useMemo } from "react";
import Clappr from "clappr";

import useAuth from "src/hooks/useAuth";
import { useSelector } from "src/redux/store";

export default function LiveVideoPreview({ id, size }) {
  const { user } = useAuth();
  const { selectedLive, defaultChannelData } = useSelector(
    (state) => state.mainPage
  );

  const source = useMemo(() => {
    return selectedLive === ""
      ? `${user.livepreview}/${defaultChannelData[0].appName}/playlist.m3u8`
      : `${user.livepreview}/${defaultChannelData[0].appName}/${selectedLive}/playlist.m3u8`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLive]);

  useEffect(() => {
    let width = "100%",
      height = 300;
    if (size) {
      width = size.width || width;
      height = size.height || height;
    }
    let clappr_player = new Clappr.Player({
      parentId: `#${id}`,
      source,
      poster: "liveprev.png",
      mediacontrol: { seekbar: "#ACD075", buttons: "#8EC044" },
      width: width || "100%",
      height: height || 360,
      borderRadius: "5px",
      hlsjsConfig: {
        enableWorker: true,
      },
    });
    clappr_player.getPlugin("markers-plugin");

    return () => {
      clappr_player.destroy();
      clappr_player = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  return (
    <div
      className="panda-video-preview-component"
      style={{ width: "100%", height: "auto" }}
    >
      <div className="player" id={id} />
    </div>
  );
}
