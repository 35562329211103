import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Draggable } from "react-beautiful-dnd";

import { CheckBox } from "src/components";
import { makeTimeDuration } from "src/utils";

const getItemStyle = (isDragging, draggableStyle, vod) => ({
  // userSelect: "none",
  backgroundColor: isDragging && (vod ? "white" : "red"),
  ...draggableStyle,
});

const getTDStyle = (isDragging, draggableStyle, name) => ({
  width: isDragging ? (name === "item" ? "780px" : "100px") : "auto",
  textAlign: "center",
});

export default function DetailItem({
  item,
  index,
  clickedIdx,
  channels,
  onClick,
  onRemove,
  handleSelectItem,
}) {
  return (
    <Draggable key={item.id} index={index} draggableId={`${item.id}`}>
      {(provided, snapshot) => (
        <tr
          key={item.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...(!snapshot.isDragging
              ? {
                  backgroundColor:
                    clickedIdx === index ? "#8EC044" : "transparent",
                }
              : {}),
            ...(snapshot.isDragging
              ? getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                  item.vod
                )
              : {}),
          }}
          onClick={onClick}
          // sx={{
          //   backgroundColor: (theme) =>
          //     snapshot.isDragging ? theme.palette.background.neutral : 'transparent'
          //   // ...provided.draggableProps.style
          // }}
        >
          <td style={{ width: "100px" }}></td>
          <td className="center">
            <CheckBox
              onChange={handleSelectItem(item)}
              color={clickedIdx === index && "white"}
            />
          </td>
          <td
            className="center"
            style={getTDStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              "id"
            )}
          >
            {channels.scheduleItem && item.id === channels.scheduleItem.id && (
              <i className="fa fa-arrow-right" style={{ color: "green" }}></i>
            )}
            {item.id}
          </td>
          <td
            className="center"
            style={getTDStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              "item"
            )}
          >
            <div className="wrap-content">{item.item}</div>
          </td>
          <td
            className="center"
            style={getTDStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              "duration"
            )}
          >
            {makeTimeDuration(item.duration, 2)}
          </td>
          <td
            className="center"
            style={getTDStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              "action"
            )}
          >
            <div className="actions">
              <Tooltip title="Delete" placement="top">
                <IconButton onClick={onRemove}>
                  <HighlightOffIcon sx={{ color: "black" }} />
                </IconButton>
              </Tooltip>
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
}
