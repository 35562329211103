import themeColor from "src/constants/themeColor";

export const themeColorArray = {
  primary: themeColor["--primary-color"],
};

export const alignArray = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
};
