import { useState } from "react";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from "src/components/Select/MuiSelect";
import MuiSvgIconButton from "src/components/SvgButton/MuiSvgIconButton";
import { useSelector } from "src/redux/store";
import { truncateString } from "src/utils";
import useMainModal from "src/hooks/useMainModal";

export default function PlayListActionGroup({
  disabled = true,
  selectedFileList = [],
}) {
  const showMainModal = useMainModal();
  const { programTableList, defaultChannelData } = useSelector(
    (state) => state.mainPage
  );
  const [selectedPlaylist, setSelectedPlaylist] = useState("");

  const handleSelectChange = (e) => {
    setSelectedPlaylist(e.target.value);
    showMainModal({
      modalName: "add-items-to-playlist",
      status: true,
      items: selectedFileList,
      playlistId: e.target.value,
    });
  };

  const menuItems = () => {
    return programTableList
      .filter((i) => i.id !== defaultChannelData[0].defaultPlaylist.id)
      .map((list, index) => {
        return list.startTime >= new Date().valueOf() - 86400000 ? (
          <MenuItem value={list.id} key={index}>
            {truncateString(list.title, 15)}
          </MenuItem>
        ) : null;
      });
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="end">
      <MuiSvgIconButton
        icon="add-playlist"
        disabled={disabled}
        onClick={() =>
          showMainModal({
            modalName: "add-playlist",
            status: true,
            items: selectedFileList,
          })
        }
      />
      <MuiSvgIconButton
        icon="add-time"
        disabled={disabled}
        onClick={() =>
          showMainModal({
            modalName: "add-time",
            status: true,
            items: selectedFileList,
          })
        }
      />
      <FormControl disabled={disabled}>
        <MuiSelect
          placeholder="Select Playlist"
          value={selectedPlaylist}
          onSelect={handleSelectChange}
          sx={{ minWidth: 150 }}
        >
          {programTableList.length > 1 && menuItems()}
        </MuiSelect>
      </FormControl>
    </Stack>
  );
}
